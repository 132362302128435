import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Route, useHistory, Link } from 'react-router-dom';
import Div100vh, { use100vh } from 'react-div-100vh';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab, faTwitter, faSnapchat } from '@fortawesome/free-brands-svg-icons'
// import { faTwitter } from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router-dom';

// ALGOLIA SEARCH
import algoliasearch from 'algoliasearch';
import { InstantSearch, SearchBox, Hits } from 'react-instantsearch-dom';

// Firebase
import { db } from './config/firebase-config';
import { collection, getDocs, query, where, doc } from 'firebase/firestore';

// Redux
import { connect } from 'react-redux';

// Routing
import Routes from './components/routes/Routes';

// Actions
import { setAlert } from './actions/alertActions';
import { toggleSideNav, togglePostModal, setProfileModal, set_scroll_to_top } from './actions/navActions';
import { setUsers, getUserById, clearUser } from './actions/authActions';
import { clearEditorText, clearEditorFiles, clearUrls } from './actions/postActions';

// Components - imported
import Sidebar from './components/sidebar/Sidebar';
import Backdrop from './components/common/Backdrop';

// Modal
import DefaultModal from './components/modal/DefaultModal';
import Modal from './components/modal/Modal';
import AuthModal from './components/modal/AuthModal';
import ModalContainer from './components/modal/ModalContainer';
import CommentModal from './components/modal/CommentModal';

// Components - imported
import { Button } from '@material-ui/core';
import Spinner from './components/common/Spinner';
import TweetBox from './components/TweetBox/TweetBox';

// Icons - material UI
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

const Body = ({ 
    clearEditorText, 
    clearEditorFiles, 
    clearUrls,
    auth,
    setProfileModal,
    set_scroll_to_top,
    togglePostModal,
    toggleSideNav,
    nav: { 
        sideNav,
        postModal,
        commentModal,
        page,
        loading,
    },
    post: {
        post,
        postUploading,
        postUploadingImgs
    },
}) => {

    // Window width state
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // SideNav and Overlay should disappear if not isMobile
    const [resetSideNav, setResetSideNav] = useState(false);

    // Modal toggles
    const [discardModal, setDiscardModal] = useState(false);
    const [postUploadingModal, setPostUploadingModal] = useState(false);
    const [postUploadingImgsModal, setPostUploadingImgsModal] = useState(false);
    const [accountUpdatingModal, setAccountUpdatingModal] = useState(false);

    let history = useHistory();

    // Get URL parameters
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    const showProfileModal = url.searchParams.get("setUp");
    const showPostModal = url.searchParams.get("compose");


    // Listen for window resize
    useEffect(() => {
        window.addEventListener('resize', () => handleWindowSizeChange());

        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // SideNav and Overlay should disappear if not isMobile
    useEffect(() => {
        if(resetSideNav) {
            toggleSideNav();
            setResetSideNav(false);
        }
    }, [resetSideNav]);

    
    // Update window width on resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Show the Edit Profile modal if showProfileModal is true
    useEffect(() => {
        if(auth.user && showProfileModal === 'true') {
            setProfileModal(true);
        }

    }, [page, auth.user])


    const toggleDiscardModal = () => {
        setDiscardModal(!discardModal);
    }

    const togglePostUploadingModal = () => {
        setPostUploadingModal(!postUploadingModal);
    }

    const togglePostUploadingImgsModal = () => {
        setPostUploadingImgsModal(!postUploadingImgsModal);
    }

    const toggleAccountUpdatingModal = () => {
        setAccountUpdatingModal(!accountUpdatingModal);
    }

    // Modal toggle logic

    const handleDiscard = () => {
        setDiscardModal(false);
        togglePostModal();
        
        clearEditorFiles();
        clearUrls();
        clearEditorText();
    }

    // Determine window size breakpoints
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1170;

    const pageHeight = use100vh();
    // const bodyHeight = pageHeight ? pageHeight - 56 : '95vh';

    if(isMobile && postModal && !loading && page !== 'create') {
        
        // If the user is on a mobile device, the post modal is open,
        // the page is not in the loading state, and the page is not 'create',
        // redirect the user to the Create Post page with a query parameter for navigation.
        history.push({
            pathname: '/create/post',
            search: `?goBack=${page}`,
        })
    }

    if(isMobile && commentModal && !loading && page !== 'create') {
        
        // If the user is on a mobile device, the post modal is open,
        // the page is not in the loading state, and the page is not 'create',
        // redirect the user to the Create Post page with a query parameter for navigation.
        history.push({
            pathname: `/create/comment/${post._id}`,
            search: `?goBack=${page}`,
        })
    }

    let backdrop;

    // Create a backdrop component if the sideNav is open
    if (sideNav) {
        backdrop = <Backdrop click={toggleSideNav} />;
    }

    // SideNav and Overlay should disappear if not isMobile
    if (sideNav && !isMobile && !resetSideNav) {

        // If the sideNav is open, the device is not a mobile device,
        // and the resetSideNav state is not set, set the resetSideNav state to true
        // to show the sideNav and overlay again.
        setResetSideNav(true);
    }

    if (sideNav && isMobile && resetSideNav) {

        // If the sideNav is open, the device is a mobile device,
        // and the resetSideNav state is set, set the resetSideNav state to false
        // to hide the sideNav and overlay.
        setResetSideNav(false);
    }

    return (
        <Fragment>
            
            {/* Main App */}
            <div className={sideNav ? "app open": "app"} style={{ height: pageHeight }}>

                {/* Sidebar */}
                {!isMobile ? <Sidebar isMobile={isMobile} isTablet={isTablet} /> : <></> }

                {backdrop}

                {/* Routes => Different Pages */}
                <Route component={Routes} />

            </div>

            {/* Authentication Modal - if not logged in && triggered an Action */}
            <AuthModal />

            {/* Create Post Modal */}
            <ModalContainer show={postModal} onClose={toggleDiscardModal}>
                <CommentModal>

                    {/* Close Button */}
                    <div className="closeBtn__container start modalClose">
                        <button onClick={toggleDiscardModal} className="secondaryBtn">
                            <CloseOutlinedIcon /> Close
                        </button>
                    </div>

                    {/* Tweet Box */}
                    <TweetBox modal compose />
                </CommentModal>
            </ModalContainer>

            {/* Discard Post Modal */}
            <ModalContainer show={discardModal} onClose={toggleDiscardModal}>
                <Modal>

                    {/* Modal Title */}
                    <div>
                        <h2>Discard Post?</h2>
                    </div>

                    {/* Modal Description */}
                    <p>Are you sure? Your draft will not be saved.</p>
                    
                    {/* Modal Actions */}
                    <Button 
                        onClick={handleDiscard}
                        variant="outlined" 
                        className="sidebar__tweet black"
                        fullWidth
                    >
                        Discard
                    </Button>
                    <Button 
                        onClick={toggleDiscardModal}
                        variant="outlined" 
                        className="sidebar__tweet ghost blackText"
                        fullWidth
                    >
                        Keep Writing
                    </Button>
                </Modal>
            </ModalContainer>

            {/* Discard Post Modal */}
            <ModalContainer show={postUploading} onClose={togglePostUploadingModal}>
                <Modal>

                    {/* Modal Title */}
                    <div>
                        <h2>Uploading Post...</h2>
                    </div>

                    {/* Modal Description */}
                    <p>Patience is a virtue.</p>
                    
                    <Spinner />
                </Modal>
            </ModalContainer>

            {/* Discard Post Modal */}
            <ModalContainer show={postUploadingImgs} onClose={togglePostUploadingImgsModal}>
                <Modal>

                    {/* Modal Title */}
                    <div>
                        <h2>Uploading Photos...</h2>
                    </div>

                    {/* Modal Description */}
                    <p>Patience is a virtue.</p>
                    
                    <Spinner />
                </Modal>
            </ModalContainer>

            <ModalContainer show={auth.profile_update_loading} onClose={toggleAccountUpdatingModal}>
                <Modal>

                    {/* Modal Title */}
                    <div>
                        <h2>Updating account data...</h2>
                    </div>

                    {/* Modal Description */}
                    <p>This can take up to 30 seconds.</p>
                    <p>DO NOT re-load the page!</p>
                    
                    <Spinner />
                </Modal>
            </ModalContainer>

        </Fragment>
    )
}



Body.propTypes = {
    // Props for navigation
    nav: PropTypes.object.isRequired,
    // Props for authentication
    auth: PropTypes.object.isRequired,
    // Props for the post state
    post: PropTypes.object.isRequired,
    // Function to set an alert
    setAlert: PropTypes.func.isRequired,
    // Function to toggle the side navigation
    toggleSideNav: PropTypes.func.isRequired,
    // Function to toggle the post modal
    togglePostModal: PropTypes.func.isRequired,
    // Function to set the profile modal
    setProfileModal: PropTypes.func.isRequired,
    set_scroll_to_top: PropTypes.func.isRequired,
    // Function to set users
    setUsers: PropTypes.func.isRequired,
    // Function to get a user by ID
    getUserById: PropTypes.func.isRequired,
    // Function to clear the current modalUser
    clearUser: PropTypes.func.isRequired,
    // Function to clear the editor text
    clearEditorText: PropTypes.func.isRequired, 
    // Function to clear the editor files
    clearEditorFiles: PropTypes.func.isRequired,
    // Function to clear URLs
    clearUrls: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    // Mapping the navigation state
    nav: state.nav,
    // Mapping the authentication state
    auth: state.auth,
    // Mapping the post state
    post: state.post,
    // Mapping the notification state for the alert badges
    notification: state.notification,
    // Mapping the chat state for the alert badge
    chat: state.chat,
})

export default connect(mapStateToProps, { 
    // Connecting actions to the component
    setAlert,
    toggleSideNav, 
    togglePostModal,
    setProfileModal,
    setUsers,
    getUserById,
    clearUser,
    clearEditorText, 
    clearEditorFiles, 
    clearUrls,
    set_scroll_to_top
})(Body);

import React, { Fragment, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Actions
import { signInWithGoogle } from '../../actions/authActions';
import { goBack, set_scroll_to_top } from '../../actions/navActions';

// Firebase
import { db } from '../../config/firebase-config';
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, onSnapshot, orderBy, startAfter, limit } from 'firebase/firestore';

// Components - imported

// Modal

// Icons - material UI
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

// Imgs
import tunde_logo2 from '../../utils/imgs/tunde_logo2.png';

const Layout = ({ 
    children, 
    user, 
    page, 
    auth, 
    goBack,
    set_scroll_to_top,
    nav: {
        scroll_to_top
    }, 
    post: { post }, 
    game: { game },
    project: { project },
    handleScroll,
}) => {
    // Screen Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Determine if the window is in mobile or tablet view
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    const scrollingElementRef = useRef(null);

    useEffect(() => {
        // Add an event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    useEffect(() => {
        if(scroll_to_top) {
            scrollToTop();
        }
    }, [scroll_to_top]);

    

    // Update the window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    const scrollToTop = () => {
        if (scrollingElementRef.current) {
          scrollingElementRef.current.scrollTop = 0;
        }

        set_scroll_to_top(false);
    };


    return (
        <Fragment>

            <div className="feed__container">
                <div onScroll={handleScroll} className="feed" ref={scrollingElementRef}>
                    
                    {/**Header */}
                    <div className="feed__header" id="feed-header">
                            
                        {/* Render the header content based on the current page and filter */}

                        {page === 'profile' && (
                            <div className="layout__profileHeader">

                                <div style={{width: '10px'}}></div>

                                {/* Header Content */}
                                <div className="profile__headerText">
                                    <h3 style={{display:'flex'}}>
                                        <div> 
                                            <img className="sidebar__twitterIcon" src={tunde_logo2} style={{maxHeight: '24px', marginLeft: '0px', marginBottom: '0px'}} alt="logo" />
                                        </div>
                                
                                    </h3>
                                </div>

                            </div>
                        )}

                        {page === 'profile edit' && (
                            <div className="layout__profileHeader">

                                {/* Alert Badge container with back arrow */}
                                <div onClick={goBack} className="profile__btnContainer">
                                    <ArrowBackIcon />
                                </div>

                                {/* Title for the Edit Profile page */}
                                <div className="profile__headerText">
                                    <h3>Edit Profile</h3>
                                </div>
                                    
                            </div>
                        )}

                        {page === 'post' && (
                            <div className="layout__profileHeader">

                                {/* Alert Badge container with back arrow */}
                                <div onClick={goBack} className="profile__btnContainer">
                                    <ArrowBackIcon />
                                </div>
                                <div className="profile__headerText">

                                    {/* Title for a specific post */}
                                    <h3>
                                        {post ? `${post.user.first_name}'s post` : 'Post'}
                                    </h3>
                                </div>
                                    
                            </div>
                        )}

                        {(page === 'game' || page === 'project') && (
                            <div className="layout__profileHeader">

                                {/* Alert Badge container with back arrow */}
                                <div onClick={goBack} className="profile__btnContainer">
                                    <ArrowBackIcon />
                                </div>
                                <div className="profile__headerText">

                                    {/* Title for a specific post */}

                                    {page === 'game' && (
                                        <h3>
                                            {game?.user ? `${game.user.first_name}'s game` : 'Game'}
                                        </h3>
                                    )}
                                    
                                    {page === 'project' && (
                                        <h3>
                                            {project?.user ? `${project.user.first_name}'s project` : 'Project'}
                                        </h3>
                                    )}
                                    
                                </div>
                                    
                            </div>
                        )}
                        
                    </div>

                    {/* Display Layout content (posts, notifications, etc.) */}
                    {children}
                </div>

            </div>
        </Fragment>
    )
}

Layout.propTypes = {
    // Prop type validation for authentication
    auth: PropTypes.object.isRequired,
    // Prop type validation for navigation
    nav: PropTypes.object.isRequired,
    // Prop type validation for post
    post: PropTypes.object.isRequired,
    game: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired,
    signInWithGoogle: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    set_scroll_to_top: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    // Mapping the AUTHENTICATION state
    auth: state.auth,
    // Mapping the NAVIGATION state
    nav: state.nav,
    // Mapping the POST state
    post: state.post,
    game: state.game,
    project: state.project
})

export default connect(mapStateToProps, {
    
    // Connecting actions to the component
    signInWithGoogle,
    goBack,
    set_scroll_to_top
})(Layout);

import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import mixpanel from 'mixpanel-browser';

// Icons - material UI
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const Main_Settings = ({
    auth,
    handleSlideMenu,
    formData,
}) => {

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Screen Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Listen for window resize event and update window width state
    useEffect(() => {

        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Clean up by removing event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Form data
    const { 
        username, 
        email, 
    } = formData;

    // Go back to main settings page
    const goBack = () => {
        window.location.href = '/settings';
    }

    // Determine mobile and tablet screen sizes
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    // Initialize Mixpanel and track settings account page view
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Settings Account Page View");
    }

    // Check if the code is running in production and Mixpanel event hasn't been sent yet
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {

        // Initialize Mixpanel and track the event
        handleMixpanel();

        // Set the flag to indicate that Mixpanel event has been sent
        setSentMixpanel(true);
    }

    return (
        <div className="settings">

            {/* Settings Header */}
            <div className="settings__header">
                {isTablet && (

                    // Back Button & Badge Value
                    <div onClick={goBack} className="profile__btnContainer">
                        <ArrowBackIcon />
                    </div>
                )}

                {/* Title */}
                <div onClick={handleSlideMenu} className="settings__headerText">
                    <h3>
                        Account settings
                    </h3>
                </div>
            </div>

            <div className="settings__body">

                {/* Username */}
                <a style={{textDecoration:'none'}} href="/settings/account/screen_name">
                    <div className="settings__item">
                        <div>
                            <div style={{fontSize:'15px', fontWeight:'500', lineHeight:'20px', overflowWrap:'break-word'}}>
                                <span>Username</span>
                            </div>
                            <div style={{color: 'rgb(83, 100, 113)', fontSize:'13px', fontWeight:'500', lineHeight:'16px', overflowWrap:'break-word'}}>
                                <span>@{username}</span>
                            </div>
                        </div>
                        <ChevronRightIcon />
                    </div>
                </a>

                {/* Password */}
                <a style={{textDecoration:'none'}} href="/settings/account/password">
                    <div className="settings__item">
                        <div>
                            <div style={{fontSize:'15px', fontWeight:'500', lineHeight:'20px', overflowWrap:'break-word'}}>
                                <span>Password</span>
                            </div>
                            <div style={{color: 'rgb(83, 100, 113)', fontSize:'13px', fontWeight:'500', lineHeight:'16px', overflowWrap:'break-word'}}>
                                <span>Change your password</span>
                            </div>
                        </div>
                        <ChevronRightIcon />
                    </div>
                </a>

                {/* Email */}
                <a style={{textDecoration:'none'}} href="/settings/account/email">
                    <div className="settings__item">
                        <div>
                            <div style={{fontSize:'15px', fontWeight:'500', lineHeight:'20px', overflowWrap:'break-word'}}>
                                <span>Email</span>
                            </div>
                            <div style={{color: 'rgb(83, 100, 113)', fontSize:'13px', fontWeight:'500', lineHeight:'16px', overflowWrap:'break-word'}}>
                                <span>{email}</span>
                            </div>
                        </div>
                        <ChevronRightIcon />
                    </div>
                </a>

                {/* Joined Date */}
                <div className="settings__item noHover">
                    <div>
                        <div style={{fontSize:'15px', fontWeight:'500', lineHeight:'20px', overflowWrap:'break-word'}}>
                            <span>Account created</span>
                        </div>
                        <div style={{color: 'rgb(83, 100, 113)', fontSize:'12px', fontWeight:'500', lineHeight:'20px', overflowWrap:'break-word'}}>
                            <span>{moment(auth.user.date).format('MMMM Do YYYY, h:mm:ss a')}</span>
                        </div>
                    </div>
                </div>

                <div className='no-rides'></div>
            </div>
        </div>
    )
}

export default Main_Settings;

import { v4 as uuidv4 } from 'uuid';
import { SET_ALERT, REMOVE_ALERT } from './types';

// Action creator to set an alert
export const setAlert = (msg, alertType, timeout = 2600) => dispatch => {
    
    // Generate a unique identifier for the alert
    const id = uuidv4();

    // Dispatch an action to set the alert
    dispatch({
        type: SET_ALERT,
        payload: { msg, alertType, id }
    });

    // Set a timeout to automatically remove the alert after the specified duration
    setTimeout(() => dispatch({ 
        type: REMOVE_ALERT, 
        payload: id 
    }), timeout);
}

// Action creator to remove an alert
export const removeAlert = (id) => dispatch => {

    // Dispatch an action to remove the alert
    dispatch({ 
        type: REMOVE_ALERT, 
        payload: id 
    })

}
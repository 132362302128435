import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const Backdrop = ({ click }) => (

    // Render a backdrop element
    <div className="backdrop" onClick={click} />
);

// Prop types for the Backdrop component
Backdrop.propTypes = {
    // Prop type validation for navigation
    nav: PropTypes.object.isRequired,
}

// Map the nav state from Redux store to the component props
const mapStateToProps = state => ({
    nav: state.nav
})

// Connect the Backdrop component to the Redux store and export it
export default connect(mapStateToProps, null)(Backdrop);


import React, { Fragment, useEffect, useState } from 'react';

// Components - material UI
import { Button } from '@material-ui/core';

// Icons - material UI
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const Password = ({
    currentPassword,
    password,
    password2,
    passwordTodo,
    onChange,
    auth,
    history
}) => {

    // Screen Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {

        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Cleanup the event listener when the component unmounts
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    const goBack = () => {
        history.goBack();
    }

    // Determine mobile and tablet screen sizes
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    return (
        <div className="settings">

            {/* Header */}
            <div className="settings__header">

                {/* Back Btn Icon & Badge Value */}
                <div onClick={goBack} className="profile__btnContainer">
                    <ArrowBackIcon />
                </div>

                {/* Title */}
                <div className="settings__headerText">
                    <h3>
                        Change your password
                    </h3>
                </div>
            </div>

            {/* Main Content */}
            <div className="settings__body">

                {/* Password Inputs */}
                <div style={{width: '100%', boxSizing:'border-box', padding:'10px', display:'flex', flexDirection:'column', alignItems:'center', borderBottom:'1px solid #e6ecf0'}}>
                    
                    {/* Current Password */}
                    <input
                        type="password"
                        placeholder="Current password"
                        className="input"
                        name="currentPassword"
                        value={currentPassword}
                        maxlength="30"
                        onChange={e => onChange(e)}
                        style={{width: '93%'}}
                    />
                    <div style={{width:'93%'}} className="settings__forgotPassword">
                        <a href={`/forgotpassword`}>Forgot password?</a>
                    </div>

                    {/* New Passowrd */}
                    <input
                        type="password"
                        placeholder="New password"
                        className="input"
                        name="password"
                        value={password}
                        maxlength="30"
                        onChange={e => onChange(e)}
                        style={{width: '93%'}}
                    />

                    {/* Re-enter New Password */}
                    <input
                        type="password"
                        placeholder="Re-enter new password"
                        className="input"
                        name="password2"
                        value={password2}
                        maxlength="30"
                        onChange={e => onChange(e)}
                        style={{width: '93%'}}
                    />
                </div>

                {/* Submit */}
                <div style={{width: '100%', boxSizing:'border-box', padding:'0 10px', display:'flex', justifyContent:'flex-end'}}>
                    <Button 
                        variant="outlined" 
                        className="sidebar__tweet save" 
                        onClick={passwordTodo}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default Password;

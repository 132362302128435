import React from 'react';
import spinner from './spinner.gif';

// A functional component representing a spinner/loading animation
export default function Spinner() {
  return (
    <div>

      {/* Render an image element with the spinner animation */}
      <img 
        src={spinner} 
        style={{width: '200px', margin: 'auto', display: 'block' }}
        alt="Loading..."
        />
    </div>
  )
}
import React, {  useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';

// Actions 
import { removeAlert } from '../../../actions/alertActions';

import Notification from './Notification';


// A functional component representing the container for displaying alerts
const AlertContainer = ({ alerts, removeAlert, post: { last_post_added} }) => {

    let alertList;

    // If there are alerts, map through them to create Notification components
    if(alerts !== null && alerts.length > 0) {
        alertList = alerts.map(alert => <Notification alert={alert} removeAlert={removeAlert} last_post_added={last_post_added} />)  
                    
    }

    return (
        <div className="alert-popup-container">
            {/* Display alerts */}
            {alertList}
        </div> 
        
    )
}

AlertContainer.propTypes = {
    // Required prop: Object representing the posts state
    post: PropTypes.object.isRequired,
    // Prop type validation for the array of alerts
    alerts: PropTypes.array.isRequired,
    // Prop type validation for function of removing alerts
    removeAlert: PropTypes.func.isRequired,
}

// Map these states from Redux store to the component props
const mapStateToProps = state => ({
    alerts: state.alert,
    post: state.post
});

export default connect(mapStateToProps, { 

    // Connecting actions to the component
    removeAlert 
})(AlertContainer)

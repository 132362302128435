import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types'

// Redux
import { connect } from 'react-redux';

// Actions
import { goBack } from '../actions/navActions';
import { getUserByUsername } from '../actions/authActions';

// Layout Components
import About from '../components/morePage/About_Page';
import Projects from '../components/morePage/Projects_Page';
import Faq from '../components/morePage/Faq_Page';
import MorePage_Nav from '../components/morePage/MorePage_Nav';
import Help from '../components/settings/Help_Settings';

// Modal

// Components - imported
import { Button } from '@material-ui/core';

// Actions - imported
import { setPage, add_To_Recent_History } from '../actions/navActions';
import { setAlert } from '../actions/alertActions';
import { editUsername, changePassword, changeUserEmail } from '../actions/authActions';
import { toggleSideNav } from '../actions/navActions';

const MorePage = ({ 
    goBack,
    setPage, 
    add_To_Recent_History,
    toggleSideNav, 
    auth, 
    nav, 
    history,
    getUserByUsername
}) => {
    
    // Screen Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Which page to display
    const [page, handlePage] = useState(null);

    // Get the current URL and extract the "redirect" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);

    useEffect(() => {

        // Update window width on resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Set the current page based to 'settings'
        setPage('more page');
        add_To_Recent_History(url.pathname);  // current url

        let pathname = window.location.pathname;

        const pathArray = pathname.split('/');

        const morePage = pathArray[pathArray.length - 1];

        handlePage(morePage);
        
        // Cleanup the event listener when the component unmounts
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());

    }, [window.location, auth.user]);

    useEffect(() => {

        getUserByUsername('tundeadepitan');

    }, []);


    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Determine mobile and tablet screen sizes
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    return (
        <Fragment>
            <div className="inbox__container">
                
                {/* Navigation */}
                {!isTablet && (
                    <MorePage_Nav 
                        sideNav={nav.sideNav} 
                        handleSlideMenu={toggleSideNav} 
                        auth={auth} 
                        page={nav.page} 
                        history={history} 
                        goBack={goBack}
                    />
                )}

                {/* About page */}
                {page == 'about' && <About handleSlideMenu={toggleSideNav} history={history} auth={auth} goBack={goBack} />}

                {/* Projects page */}
                {page == 'projects' && <Projects campus_id={nav.campus_id} handleSlideMenu={toggleSideNav} history={history} auth={auth} goBack={goBack} />}

                {/* FAQ page */}
                {page == 'faq' && <Faq handleSlideMenu={toggleSideNav} history={history} auth={auth} goBack={goBack} />}

                {/* End */}

                {/* Other setting pages */}

                {page == 'help' && <Help campus_name={nav.campus_name} handleSlideMenu={toggleSideNav} history={history} auth={auth} goBack={goBack} />}
                
            </div>
        </Fragment>
    )
}

MorePage.propTypes = {
    auth: PropTypes.object.isRequired,
    nav: PropTypes.object.isRequired,
    setPage: PropTypes.func.isRequired,
    add_To_Recent_History: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    toggleSideNav: PropTypes.func.isRequired,
    editUsername: PropTypes.func.isRequired,
    changePassword: PropTypes.func.isRequired, 
    changeUserEmail: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    getUserByUsername: PropTypes.func.isRequired
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    auth: state.auth,
    nav: state.nav,
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    setPage, 
    add_To_Recent_History,
    setAlert, 
    toggleSideNav, 
    editUsername, 
    changePassword, 
    changeUserEmail, 
    goBack,
    getUserByUsername
})(MorePage);

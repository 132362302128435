import {
    PROJECTS_LOADING,
    GET_PROJECTS,
    SET_PROJECTS,
    SET_LAST_PROJECTS,
    GET_PROJECT,
    UPDATE_PROJECT_LIKES,
    ADD_PROJECT,
    DELETE_PROJECT,
    PROJECT_COMMENTS_LOADING,
    GET_PROJECT_COMMENTS,
    SET_PROJECT_COMMENTS,
    ADD_PROJECT_COMMENT,
    REMOVE_PROJECT_COMMENT,
    CLEAR_PROJECTS,
    CLEAR_PROJECT,
    PROJECT_ERROR,
    UPDATE_LAST_PROJECT_ADDED,
    PROJECT_UPLOADING,
    PROJECT_UPLOADING_IMGS,
    SET_SHOW_BOTTOM_SPINNER_PROJECT, 
    SET_LAST_PAGE_DOC_PROJECT, 
    SET_NO_MORE_PROJECTS
} from '../actions/types';

const initialState = {
    projects: [],
    project: null,
    no_more_projects: false,
    show_bottom_spinner: false,
    last_page_doc: 0,
    loading: true,
    last_project_added: null,
    comments: [],
    hiddenComments: [],
    loadingComments: true,
    projectUploading: false,
    projectUploadingImgs: false,
    error: {}
}

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case PROJECTS_LOADING:

            // Set the loading state to true
            return {
                ...state,
                loading: true
            };
        case CLEAR_PROJECTS:

            // Clear the projects state
            return {
                ...state,
                projects: []
            };
        case CLEAR_PROJECT:

            // Clear the project state
            return {
                ...state,
                project: null
            };
        case GET_PROJECTS:

            // Set the projects state to the payload value and set loading to false
            return {
                ...state,
                projects: payload,
                loading: false
            };
        case SET_PROJECTS: {

            // Merge the existing projects with the payload projects, and update the projects state
            const projects = action.payload;
            let tempProjects = projects; 
            
            if(state.projects.length > 0) {
                tempProjects= [...state.projects, ...tempProjects ];
            }

            // Use a Map to remove potential duplicate projects based on the _id field (in case the same function is called more than it should be)
            const uniqueProjects = Array.from(
                new Map(tempProjects.map(project => [project._id, project])).values()
            );


            return {
                ...state,
                projects: uniqueProjects,
                loading: false
            };
        }
        case SET_LAST_PROJECTS: {

            // Merge the existing projects with the payload projects, and update the projects state
            const projects = action.payload;
            let tempProjects = projects; 
            
            if(state.projects.length > 0) {
                tempProjects= [...state.projects, ...tempProjects ];
            }

            return {
                ...state,
                projects: tempProjects,
                no_more_projects: true,
                loading: false
            };
        }
        case GET_PROJECT:

            // Set the project state to the payload value and set loading to false
            return {
                ...state,
                project: payload,
                loading: false
            }
        case ADD_PROJECT:

            // Add the new project to the beginning of the projects state and set loading to false
            return {
                ...state,
                projects: [payload, ...state.projects],
                loading: false
            };
        case UPDATE_LAST_PROJECT_ADDED:

            // Add the new project to the beginning of the projects state and set loading to false
            return {
                ...state,
                last_project_added: payload,
            };
        case PROJECT_UPLOADING:

            // Add the new project to the beginning of the projects state and set loading to false
            return {
                ...state,
                projectUploading: payload,
            };
        case PROJECT_UPLOADING_IMGS:

            // Add the new project to the beginning of the projects state and set loading to false
            return {
                ...state,
                projectUploadingImgs: payload,
            };  
        case DELETE_PROJECT:

            // Remove the deleted project from the projects state and set loading to false
            return {
                ...state,
                projects: state.projects.filter(project => project._id !== payload),
                loading: false
            }
        case UPDATE_PROJECT_LIKES:

            // Update the likes of the project and projects state based on the payload data
            let tempProject = state.project;

            if(tempProject) {
                if(tempProject._id === action.payload.id) { 
                    tempProject = {...tempProject, likes: action.payload.likes }
                }
            }

            return {
                ...state,
                projects: state.projects.map(project =>
                    project._id === action.payload.id ? { ...project, likes: action.payload.likes } : project
                ),
                project: tempProject
            };
        case GET_PROJECT_COMMENTS:

            // Set the comments state to the payload value and set loadingComments to false
            return {
                ...state,
                comments: payload,
                loadingComments: false
            };
        case SET_PROJECT_COMMENTS: {

            // Merge the existing comments with the payload comments, and update the comments state
            const comments = action.payload;
            let tempComments = comments; 
            
            if(state.comments.length > 0) {
                tempComments= [...tempComments, ...state.comments ];
            }

            return {
                ...state,
                comments: tempComments,
                loadingComments: false
            };
        }
        case ADD_PROJECT_COMMENT: 

            // Add the new comment to the project's comments and update the project state
            return {
                ...state,
                project: { ...state.project, comments: payload }
            }
        case REMOVE_PROJECT_COMMENT: 

            // Remove the deleted comment from the project's comments and comments state,

            if(state.project) {
                return {
                    ...state,
                    // project: { 
                    //     ...state.project, 
                    //     comments: state.project.comments.filter(
                    //         comment => comment._id !== payload
                    //     ) 
                    // },
                    comments: state.comments.filter(comment => comment._id !== payload),
                    loadingComments: false
                };
            } else {
                return {
                    ...state,
                    comments: state.comments.filter(comment => comment._id !== payload),
                    hiddenComments: [...state.hiddenComments, payload],
                    loadingComments: false
                };
            }
        case PROJECT_COMMENTS_LOADING:

            // Set the loadingComments state to true
            return {
                ...state,
                loadingComments: true
            };
        case PROJECT_ERROR:

            // Update the error state with the payload value and set loading to false
            return {
                ...state,
                error: payload,
                loading: false
            };
        case SET_SHOW_BOTTOM_SPINNER_PROJECT:
            return {
                ...state,
                show_bottom_spinner: payload
            };
        case SET_LAST_PAGE_DOC_PROJECT:

            // Add the new product to the beginning of the products state and set loading to false
            return {
                ...state,
                last_page_doc: payload
            };
        case SET_NO_MORE_PROJECTS:
            return {
                ...state,
                no_more_projects: payload
            };
        default:
            return state;
    }
}
import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import mixpanel from 'mixpanel-browser';

// Redux
import { connect } from 'react-redux';

import { db } from '../../config/firebase-config';
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, onSnapshot, orderBy, startAfter, limit } from 'firebase/firestore';

// Actions
import { getGameById, getComments } from '../../actions/gameActions';
import { setPage, add_To_Recent_History, toggleCommentModal, toggleAuthModal } from '../../actions/navActions';

// Layout Wrapper
import Layout from '../../components/layout/Layout';

// components
import { Button } from '@material-ui/core';
import Spinner from '../../components/common/Spinner';
import TweetBox from '../../components/TweetBox/TweetBox';
import Detail from '../../components/GamePage/Detail';
import Post from '../../components/Post/Post';

// Icons - material UI
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

// Modal
import CommentModal from '../../components/modal/CommentModal';
import ModalContainer from '../../components/modal/ModalContainer';
import Modal from '../../components/modal/Modal';
import TicTacToe_Readme from '../../components/common/Readme/TicTacToe_Readme';
import Pong_Readme from '../../components/common/Readme/Pong_Readme';
import SpaceInvaders_Readme from '../../components/common/Readme/SpaceInvaders_Readme';
import Snake_Readme from '../../components/common/Readme/Snake_Readme';
import Breakout_Readme from '../../components/common/Readme/Breakout_Readme';

const GamePage = ({ 
    setPage, 
    add_To_Recent_History,
    toggleAuthModal,
    toggleCommentModal,
    getGameById, 
    getComments,
    game: { 
        game, 
        comments,
        loadingComments,
        loading 
    }, 
    nav: {
        commentModal
    },
    auth, 
    match, 
    history 
}) => {
    
    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Modal toggles
    const [discardModal, setDiscardModal] = useState(false);

    // Scroll direction - for displaying top nav bar
    const [scrollDirection, setScrollDirection] = useState("up");
    const [oldScrollY, setOldScrollY] = useState(window.scrollY);
    const [gotExecuteScroll, setGotExecuteScroll] = useState(false);

    // Get the current URL and extract the "redirect" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    const page_filter = url.searchParams.get("show");

    // Determine if the device is mobile or tablet based on the `windowWidth` state
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    useEffect(() => {

        // Set the page to 'detail'
        setPage('detail');
        add_To_Recent_History(url.pathname);  // current url

        // Fetch the post data based on the `match.params.id` parameter
        getGameById(match.params.id);

    }, [ match.params.id ]);

    useEffect(() => {

        // Fetch the comments for the game if the loading state is `false` and the game data is available
        if(!loading && game) {
            getComments(game._id);
        }

    }, [ loading, game ]);

    useEffect(() => {

        // Add a resize event listener to update the `windowWidth` state
        window.addEventListener('resize', () => handleWindowSizeChange());
        
        // Cleanup the event listener when the component unmounts
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    });

    // Update the `windowWidth` state whenever the window is resized
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };
    

    // Infinite scroll AND handle top nav bar display
    const handleScroll = (e) => {
        const { offsetHeight, scrollTop, scrollHeight } = e.target

        const newScrollY = offsetHeight + scrollTop;

        // --- Handle top nav bar display
        if(isMobile) {
            if(oldScrollY < newScrollY && gotExecuteScroll){

                // scrolling down - hide top nav
                setScrollDirection("down");
    
                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.remove("active");
                }
                
            } else {
    
                // scrolling up - show top nav
                setScrollDirection("up");
    
                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.add("active");
                }
                
            }
        }
        // --- END Handle top nav bar display

        // For purposes of correctly displaying top nav when auto scrolling to bottom of comment detail page
        var scrollTimeout;
        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(function() {
            console.log('----- Scroll ended');
            setGotExecuteScroll(true);
        }, 1000);

        // update the scroll position for future comparison
        setOldScrollY(newScrollY)

    }

    // Handle create Comment Modal Toggle
    const handleToggle_CommentModal = () => {
        if(auth.user) {
            toggleCommentModal();
        } else {
            toggleAuthModal('game comment');
        }
        

    }

    // Toggle the `discardModal` 
    const handleCommentModal = () => {
        toggleDiscardModal();
    }

    // Toggle the `discardModal` 
    const toggleDiscardModal = () => {
        setDiscardModal(!discardModal);
    }

    const handleDiscard = () => {

        // Close the discard modal
        setDiscardModal(false);

        // Handle the discard
        toggleCommentModal();
    }

    let commentList;

    if(loading || game === null) {
        commentList = <></>
    } else {
        if(comments === null || loadingComments) {
            commentList = <Spinner />;
        }
        else {
            if(comments.length > 0) {
                   

                const renderPostRecursive = (gameComments) => {
                    if (Array.isArray(gameComments)) {
                        return gameComments.map((comment) => (
                            <Fragment>
                                <Post key={comment._id} postDoc={comment} />
    
                                {/* Recursive call to render comments of comments */}
                                {comment.commentsOfComments && comment.commentsOfComments.length > 0 && (
                                    <div className="comments-of-comments">
                                        {renderPostRecursive(comment.commentsOfComments[comment.commentsOfComments.length - 1])} {/* Render only the first sub-comment */}
                                    </div>
                                )}
                            </Fragment>
                        ));
                    } else {
                        return (
                            <Fragment>
                                <Post key={gameComments._id} postDoc={gameComments} />
    
                                {/* Recursive call to render comments of comments */}
                                {gameComments.commentsOfComments && gameComments.commentsOfComments.length > 0 && (
                                    <div className="comments-of-comments">
                                    {renderPostRecursive(gameComments.commentsOfComments[0])} {/* Render only the first sub-comment */}
                                    </div>
                                )}
                            </Fragment>
                        );
                    }

                };

                commentList = (
                    <div className='post_container'>
                        {renderPostRecursive(comments)}
                    </div>
                )

                // return (

                //     <Post key={comment._id} post={comment} />
                //     // <Comment 
                //     //     key={comment._id}
                //     //     comment={comment}
                //     //     postId={post._id}
                //     // /> 
                // )
            }
            else {
                commentList = (

                    <div className="no-rides">
                        <h1 className='placeholder_title'>Be the first to comment</h1>
                        <p className='placeholder_text'>Add your thoughts and get the conversation started.</p>

                        <Button 
                            onClick={handleToggle_CommentModal}
                            variant="outlined" 
                            className="sidebar__tweet minWidth black"
                        >
                            Create A Comment
                        </Button>
                    </div>
                );
            }
        }
    }

    let gameContent;

    if(loading) {
        gameContent = <Spinner />;
    }
    else {
        if(game === null) {
            gameContent = (
                <div className="no-post">
                    <p>Hmm...this page doesn’t exist. Try searching for something else.</p>
                    <Button  
                        onClick={() => window.location ='/search'}
                        className="noChats__btn"
                    >
                        Search
                    </Button>
                </div>
            );
        } else {
            gameContent =  (
                <Fragment>
                    <Detail 
                        game={game}
                        history={history}
                        isTablet={isMobile}
                        setScrollDirection={setScrollDirection}
                        setOldScrollY={setOldScrollY}
                        oldScrollY={oldScrollY}
                        page_filter={page_filter}
                    />

                    {/* <TweetBox isTablet={isTablet} handleDisplayModal={handleDisplayModal} postId={post._id} comment mobile_redirect={isMobile} /> */}

                    {/* <div className="no-rides" /> */}

                    {(page_filter === null || page_filter === 'about') && (
                        <Fragment>
                            {game._id === '06393k01BxenCd11y8Zw' && (
                                <TicTacToe_Readme />
                            )}

                            {game._id === '6uQsu5WauQjrw7a7YbMc' && (
                                <Pong_Readme />
                            )}

                            {game._id === 's3Heaj10UyIJd7oAnHJL' && (
                                <Breakout_Readme />
                            )}

                            {game._id === 'CIMrkABa55PGCZPXSBaa' && (
                                <SpaceInvaders_Readme />
                            )}

                            {game._id === 'wnpZKXOBvS2CG6qrOgz8' && (
                                <Snake_Readme />
                            )}
                        </Fragment>
                    )}

                    {(page_filter === 'comments') && (
                        <Fragment>
                            <TweetBox isTablet={isTablet} gameId={game?._id} comment mobile_redirect={isMobile} />

                            {commentList}
                        </Fragment>
                    )}

                    <div className='no-rides'>
                        {/* add space to bottom of page for better scroll experience */}
                    </div>
                </Fragment>
            );
        }
    }

    const handleMixpanel = () => {

        // Initialize Mixpanel with the provided Mixpanel ID
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);

        // Identify the current user by their ID
        mixpanel.identify(auth.user._id);

        // Track the Page View event
        mixpanel.track("Game Page View");
    }

    // Check if the code is running in production and Mixpanel event hasn't been sent yet
    if(process.env.NODE_ENV === 'production' && !sentMixpanel && auth.user !== null) {
        
        // Initialize Mixpanel and track the event
        handleMixpanel();

        // Set the flag to indicate that Mixpanel event has been sent
        setSentMixpanel(true);
    }

    return (
        <Fragment>
            <Layout handleScroll={handleScroll} page="game">

                {gameContent}

            </Layout>

            {/* Commenting Modal */}
            <ModalContainer show={commentModal} onClose={handleCommentModal}>
                <CommentModal>

                    {/* Close Button */}
                    <div className="closeBtn__container start modalClose">
                        <button onClick={handleCommentModal} className="secondaryBtn close">
                            <CloseIcon /> 
                            <span>Close</span>
                        </button>
                    </div>
                    
                    {/* Display the tweet box for commenting */}
                    <TweetBox gameId={game && game._id} modal comment />
                </CommentModal>
            </ModalContainer>

            <ModalContainer show={discardModal} onClose={toggleDiscardModal}>
                <Modal>

                    {/* Modal Title */}
                    <div>
                        <h2>Discard Comment?</h2>
                    </div>

                    {/* Modal Text  */}
                    <p>Are you sure? Your draft will not be saved.</p>
                    
                    {/* Modal Actions  */}
                    <Button 
                        onClick={handleDiscard}
                        variant="outlined" 
                        className="sidebar__tweet black"
                        fullWidth
                    >
                        Discard
                    </Button>
                    <Button 
                        onClick={toggleDiscardModal}
                        variant="outlined" 
                        className="sidebar__tweet ghost blackText"
                        fullWidth
                    >
                        Keep Writing
                    </Button>
                </Modal>
            </ModalContainer>
        </Fragment>
    )
}

GamePage.propTypes = {
    setPage: PropTypes.func.isRequired,
    add_To_Recent_History: PropTypes.func.isRequired,
    toggleAuthModal: PropTypes.func.isRequired,
    toggleCommentModal: PropTypes.func.isRequired,
    getGameById: PropTypes.func.isRequired,
    getComments: PropTypes.func.isRequired,
    game: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    nav: PropTypes.object.isRequired
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    game: state.game,
    auth: state.auth,
    nav: state.nav
})

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    setPage, 
    add_To_Recent_History,
    toggleAuthModal,
    toggleCommentModal,
    getGameById,
    getComments
})(GamePage);

import React, { useState, useEffect, Fragment } from 'react';

import mixpanel from 'mixpanel-browser';

// Actions

// Components - imported

// Icons - material UI
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const MorePage_Nav = ({ 
    sideNav, 
    handleSlideMenu, 
    auth, 
    page, 
    goBack
}) => {

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Screen Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {

        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());
        
        // Cleanup the event listener when the component unmounts
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, [window.location]);
    
    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {

        // Update the header animation based on the sideNav state
        if(sideNav) {
            document.getElementById('feed-header').classList.remove("active");
            return "feed__header";
        } else {
            setTimeout(() => {
                document.getElementById('feed-header').classList.add("active");
            }, 700)
        }
    }, [sideNav])


    // Extract the current path from the window location
    let previousURL = window.location.pathname;
    // Split the path into an array based on '/'
    var pathArray = previousURL.split( '/' );

    // Determine mobile and tablet screen sizes
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;
    const notFullScreen = windowWidth <= 1170;

     // Initialize Mixpanel and track settings Nav page view
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Settings Nav Page View");
    }

    // Check if the code is running in production and Mixpanel event hasn't been sent yet
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {
        
        // Initialize Mixpanel and track the event
        handleMixpanel();

        // Set the flag to indicate that Mixpanel event has been sent
        setSentMixpanel(true);
    }

    return (
        <div className="feed">

            {/* Nav Header */}
            <div className="feed__header" id="feed-header">
                
                {/* Icon & Text */}
                <h2 style={{display:'flex'}}>

                    <div style={{display:'flex', alignItems: 'center'}}>

                        {isMobile && (
                            <Fragment>
                                <div onClick={goBack} style={{marginRight: '10px'}} className="shopFeed__backBtn noMargin noPadding">
                                    <div>
                                        <ArrowBackIcon />
                                    </div>
                                </div>

                            </Fragment>
                        )}
                        
                        More
                    </div>
                        
                </h2>

            </div>

            <div style={{background: '#f5f8fa', color: 'grey', fontSize: '13px', fontWeight: 'bold', padding: '5px 10px'}}>
                <span>General Info</span>
            </div>

            <a style={{textDecoration:'none'}} href="/more/about">
                <div className={`settings__item pictogram ${pathArray[2] && pathArray[2].toLowerCase().includes("about") ? 'active' : null}`}>
                    <span style={{display: 'flex'}}>
                        {/* <span style={{marginRight: '5px'}}>
                            <AccountCircleOutlinedIcon />
                        </span> */}
                        About Me
                    </span>
                    <ChevronRightIcon />
                </div>
            </a>
            

            {/* Account Edit  */}
            <div style={{background: '#f5f8fa', color: 'grey', fontSize: '13px', fontWeight: 'bold', padding: '5px 10px'}}>
                <span>More Stuff</span>
            </div>

            <a style={{textDecoration:'none'}} href="/more/faq">
                <div className={`settings__item pictogram ${pathArray[2] && pathArray[2].toLowerCase().includes("faq") ? 'active' : null}`}>
                    <span style={{display: 'flex'}}>
                        {/* <span style={{marginRight: '5px'}}>
                            <MailOutlineIcon />
                        </span> */}
                        FAQ
                    </span>
                    <ChevronRightIcon />
                </div>
            </a>

            <div style={{background: '#f5f8fa', color: 'grey', fontSize: '13px', fontWeight: 'bold', padding: '5px 10px'}}>
                <span>Help & Feedback</span>
            </div>

            {/* Help */}
            <a style={{textDecoration:'none'}} href="/more/help">
                <div className={`settings__item ${pathArray[2] && pathArray[2].toLowerCase().includes("help") ? 'active' : null}`}>
                    <p>Contact Me</p>
                    <ChevronRightIcon />
                </div>
            </a>

            {/* Add space to the bottom of the page, due to the bottom nav */}
            <div style={{height: '100px'}}></div>
        </div>
    )
}

export default MorePage_Nav;

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

// Components - imported
import { Avatar, Button } from '@material-ui/core';
import Alert from '../../common/Alert';
import Logo from '../../common/Logo';

// Icons
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const AddBio = ({
    slideform1, 
    setSlideForm1,
    slideform2, 
    setSlideForm2,
    bio, 
    onChange,
    bioTodo
}) => {

    // Toggle the value of slideform1 to go back to the previous form step
    const goBack = (e) => {
        setSlideForm1(!slideform1);
    }

    const todo = (e) => {

        // Check if the bio field is not empty
        if(bio && bio.length > 0) {

            // Call the bioTodo function to handle submitting the bio form
            bioTodo(e);
        }

        // Toggle the value of slideform2 to proceed to the next form step
        setSlideForm2(!slideform2);
    }

    return (
        <div className="form-transition-element">

            {/* Back Button */}
            <div onClick={goBack} className="form__backBtn">
                <ArrowBackIcon />
            </div>

            {/* Render the Logo component */}
            <Logo />

            {/* Display the heading */}
            <div>
                <h2>Describe yourself</h2>
            </div>

            {/* Header Description */}
            <div>
                <div style={{color:'rgb(83, 100, 113)', textAlign:'center', fontSize:'14px', fontWeight:'500', marginTop:'5px', lineHeight:'20px', overflowWrap:'break-word'}}>
                    <span>What makes you special? Don't think too hard, just have fun with it.</span>
                </div>
            </div>

            {/* Input Box for Bio text */}
            <div className="modal__inputs" style={{flex:1, paddingLeft:'5px'}}>
                <textarea
                    style={{width:'94%', padding:'10px', minHeight:'100px', resize: 'none'}}
                    type="text"
                    placeholder="Your bio"
                    className="input"
                    name="bio"
                    value={bio}
                    maxlength="160"
                    onChange={e => onChange(e)}
                />

                {/* Display Bio character length */}
                <div style={{color:'rgb(27, 149, 224)', display: 'flex', justifyContent: 'flex-end', width:'95%', fontSize:'14px', overflowWrap:'break-word'}}>
                    <span>{bio && bio.length} / 160</span>
                </div>
            </div>

            {/* Slide Form Actions */}
            {/* If NO text in bio input, give the option to 'Skip' */}
            {/* If there is text then say 'Next' */}
            <Button 
                onClick={todo}
                variant="outlined" 
                className={`sidebar__tweet ${bio && bio.length > 0 ? '' : 'ghost'}`}
                fullWidth
            >
                {bio && bio.length > 0 ? 'Next' : 'Skip for now'}
            </Button>

            <div className="auth__footer">
                {/* <a href={`/register`}>Forgot password?</a>
                <a href={`/login`}>Log In</a> */}
            </div>
        </div>
    )
}

AddBio.propTypes = {

}

export default AddBio

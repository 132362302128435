import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Redux
import { connect } from 'react-redux';

// Actions
import { toggleProfileModal, setPage } from '../../../actions/navActions';

// Components - imported

// Sliding Edit Profile Form
import ProfileForm from '../ProfileModal/ProfileForm';

const SetUpProfile = ({ toggleProfileModal, setPage, history }) => {

    // Initialize the state for the window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Get the current URL and extract the "goBack" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    let previousUrl = url.searchParams.get("goBack");

    useEffect(() => {
        // Add an event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());
        
        // Set the page to 'set-up'
        setPage('set-up')

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update the window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Determine if the window is in mobile or tablet view
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    // Redirect to a specific page if not in mobile view and there is a previousUrl value.
    // Also attach the 'setUp=true' parameter to the goBack URL
    if(!isMobile) {
   
        if(previousUrl !== null) {
            history.push({
                pathname: `/${previousUrl}`,
                search: '?setUp=true'
            });
        }
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '20px 20px 0'}}>
            <ProfileForm />
        </div>
    )
}

SetUpProfile.propTypes = {
    // Prop type validation for function to toggle the profile modal
    toggleProfileModal: PropTypes.func.isRequired,
    // Prop type validation for function to Set the Page
    setPage: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { 

    // Connecting actions to the component
    toggleProfileModal, 
    setPage 
})(SetUpProfile);

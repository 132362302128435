import { combineReducers } from 'redux';
import alert from './alertReducer';
import auth from './authReducer';
import nav from './navReducer';
import post from './postReducer';
import game from './gameReducer';
import project from './projectReducer';

export default combineReducers({
    alert,
    auth,
    nav,
    post,
    game,
    project
});
import {
    POSTS_LOADING,
    GET_POSTS,
    SET_POSTS,
    SET_LAST_POSTS,
    GET_POST,
    UPDATE_POST_LIKES,
    ADD_POST,
    DELETE_POST,
    COMMENTS_LOADING,
    GET_COMMENTS,
    SET_COMMENTS,
    ADD_COMMENT,
    REMOVE_COMMENT,
    CLEAR_POSTS,
    CLEAR_POST,
    POST_ERROR,
    UPDATE_EDITOR_TEXT,
    CLEAR_EDITOR_TEXT,
    UPDATE_EDITOR_CATEGORY,
    CLEAR_EDITOR_CATEGORY,
    UPDATE_EDITOR_FILES,
    REMOVE_EDITOR_FILE,
    CLEAR_EDITOR_FILES,
    UPDATE_URLS,
    REMOVE_URL,
    CLEAR_URLS,
    UPDATE_LAST_POST_ADDED,
    POST_UPLOADING,
    POST_UPLOADING_IMGS,
    SET_SHOW_BOTTOM_SPINNER, 
    SET_LAST_PAGE_DOC, 
    SET_NO_MORE_POSTS
} from '../actions/types';

const initialState = {
    posts: [],
    post: null,
    no_more_posts: false,
    show_bottom_spinner: false,
    last_page_doc: 0,
    urls: [],
    editorFiles: [],
    editorText: null,
    editorCategory: null,
    loading: true,
    last_post_added: null,
    comments: [],
    hiddenComments: [],
    loadingComments: true,
    postUploading: false,
    postUploadingImgs: false,
    error: {}
}

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case POSTS_LOADING:

            // Set the loading state to true
            return {
                ...state,
                loading: true
            };
        case CLEAR_POSTS:

            // Clear the posts state
            return {
                ...state,
                posts: []
            };
        case CLEAR_POST:

            // Clear the post state
            return {
                ...state,
                post: null
            };
        case GET_POSTS:

            // Set the posts state to the payload value and set loading to false
            return {
                ...state,
                posts: payload,
                loading: false
            };
        case SET_POSTS: {

            // Merge the existing posts with the payload posts, and update the posts state
            const posts = action.payload;
            let tempPosts = posts; 
            
            if(state.posts.length > 0) {
                tempPosts= [...state.posts, ...tempPosts ];
            }

            // Use a Map to remove potential duplicate posts based on the _id field (in case the same function is called more than it should be)
            const uniquePosts = Array.from(
                new Map(tempPosts.map(post => [post._id, post])).values()
            );


            return {
                ...state,
                posts: uniquePosts,
                loading: false
            };
        }
        case SET_LAST_POSTS: {

            // Merge the existing posts with the payload posts, and update the posts state
            const posts = action.payload;
            let tempPosts = posts; 
            
            if(state.posts.length > 0) {
                tempPosts= [...state.posts, ...tempPosts ];
            }

            return {
                ...state,
                posts: tempPosts,
                no_more_posts: true,
                loading: false
            };
        }
        case GET_POST:

            // Set the post state to the payload value and set loading to false
            return {
                ...state,
                post: payload,
                loading: false
            }
        case ADD_POST:

            // Add the new post to the beginning of the posts state and set loading to false
            return {
                ...state,
                posts: [payload, ...state.posts],
                loading: false
            };
        case UPDATE_LAST_POST_ADDED:

            // Add the new post to the beginning of the posts state and set loading to false
            return {
                ...state,
                last_post_added: payload,
            };
        case POST_UPLOADING:

            // Add the new post to the beginning of the posts state and set loading to false
            return {
                ...state,
                postUploading: payload,
            };
        case POST_UPLOADING_IMGS:

            // Add the new post to the beginning of the posts state and set loading to false
            return {
                ...state,
                postUploadingImgs: payload,
            };  
        case DELETE_POST:

            // Remove the deleted post from the posts state and set loading to false
            return {
                ...state,
                posts: state.posts.filter(post => post._id !== payload),
                loading: false
            }
        case UPDATE_POST_LIKES:

            // Update the likes of the post and posts state based on the payload data
            let tempPost = state.post;

            if(tempPost) {
                if(tempPost._id === action.payload.id) { 
                    tempPost = {...tempPost, likes: action.payload.likes }
                }
            }

            return {
                ...state,
                posts: state.posts.map(post =>
                    post._id === action.payload.id ? { ...post, likes: action.payload.likes } : post
                ),
                post: tempPost
            };
        case GET_COMMENTS:

            // Set the comments state to the payload value and set loadingComments to false
            return {
                ...state,
                comments: payload,
                loadingComments: false
            };
        case SET_COMMENTS: {

            // Merge the existing comments with the payload comments, and update the comments state
            const comments = action.payload;
            let tempComments = comments; 
            
            if(state.comments.length > 0) {
                tempComments= [...tempComments, ...state.comments ];
            }

            return {
                ...state,
                comments: tempComments,
                loadingComments: false
            };
        }
        case ADD_COMMENT: 

            // Add the new comment to the post's comments and update the post state
            return {
                ...state,
                post: { ...state.post, comments: payload }
            }
        case REMOVE_COMMENT: 

            // Remove the deleted comment from the post's comments and comments state,

            if(state.post) {
                return {
                    ...state,
                    // post: { 
                    //     ...state.post, 
                    //     comments: state.post.comments.filter(
                    //         comment => comment._id !== payload
                    //     ) 
                    // },
                    comments: state.comments.filter(comment => comment._id !== payload),
                    loadingComments: false
                };
            } else {
                return {
                    ...state,
                    comments: state.comments.filter(comment => comment._id !== payload),
                    hiddenComments: [...state.hiddenComments, payload],
                    loadingComments: false
                };
            }
        case COMMENTS_LOADING:

            // Set the loadingComments state to true
            return {
                ...state,
                loadingComments: true
            };
        case UPDATE_EDITOR_TEXT:
            // console.log('HOLD UP HOLD UP URL');
            // console.log(payload)

            // Update the editorText state with the payload value
            const editorData = payload;

            return {
                ...state,
                editorText: editorData
            };
        case CLEAR_EDITOR_TEXT:

            // Clear the editorText state
            return {
                ...state,
                editorText: null
            };
        case UPDATE_EDITOR_CATEGORY:
            // console.log('HOLD UP HOLD UP URL');
            // console.log(payload)

            // Update the editorCategory state with the payload value
            const categoryData = payload;

            return {
                ...state,
                editorCategory: categoryData
            };
        case CLEAR_EDITOR_CATEGORY:

            // Clear the editorCategory state
            return {
                ...state,
                editorCategory: null
            };
        case UPDATE_EDITOR_FILES:

            // Update the editorFiles state with the payload value
            const fileData = payload;

            return {
                ...state,
                editorFiles: fileData
            };
        // case REMOVE_EDITOR_FILE:
        //     console.log('REMOVE INDEX');
        //     console.log(payload)

        //     const removeFileIndex = payload;
        //     // let featuredProducts = tempProd.filter(product => product.featured === true);
            
        //     let tempFiles = [...state.editorFiles ];

        //     tempFiles.splice(removeFileIndex, 1);

        //     return {
        //         ...state,
        //         editorFiles: tempFiles
        //     };
        case CLEAR_EDITOR_FILES:

            // Clear the editorFiles state
            return {
                ...state,
                editorFiles: []
            };
        case UPDATE_URLS:
            // console.log('HOLD UP HOLD UP URL');
            // console.log(payload)

            // Add the new URL to the urls state
            const urlData = payload;
            let tempUrl = urlData; 
            // let featuredProducts = tempProd.filter(product => product.featured === true);
            
            tempUrl = [...state.urls, tempUrl ];

            return {
                ...state,
                urls: tempUrl,
                loading: false
            };
        case REMOVE_URL:

            // Remove the URL at the specified index from the urls state
            console.log('REMOVE INDEX');
            console.log(payload)

            const removeIndex = payload;
            // let featuredProducts = tempProd.filter(product => product.featured === true);
            
            let tempUrls = [...state.urls ];

            tempUrls.splice(removeIndex, 1);

            return {
                ...state,
                urls: tempUrls,
                loading: false
            };
        case CLEAR_URLS:

            // Clear the urls state
            return {
                ...state,
                urls: []
            };
        case POST_ERROR:

            // Update the error state with the payload value and set loading to false
            return {
                ...state,
                error: payload,
                loading: false
            };
        case SET_SHOW_BOTTOM_SPINNER:
            return {
                ...state,
                show_bottom_spinner: payload
            };
        case SET_LAST_PAGE_DOC:

            // Add the new product to the beginning of the products state and set loading to false
            return {
                ...state,
                last_page_doc: payload
            };
        case SET_NO_MORE_POSTS:
            return {
                ...state,
                no_more_posts: payload
            };
        default:
            return state;
    }
}
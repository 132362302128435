import React from 'react';

const DefaultModal = ({ children }) => {
    return (
        <div className={`default-modal`}>
            {children}
        </div>
    )
}

export default DefaultModal;

import React from 'react'

import tunde_logo from '../../utils/imgs/tunde_logo2.png';

// A functional component representing a logo
const Logo = ({
    campus_name
}) => {

     // Render an image element with the logo
    return <img className="modal__twitterIcon" src={tunde_logo} style={{maxHeight: '40px'}} alt="logo" />;
}

Logo.propTypes = {

}

// Export component as the default export
export default Logo

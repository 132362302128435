import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types'

// Redux
import { connect } from 'react-redux';

// Actions
import { goBack } from '../actions/navActions';

// Layout Components
import MainSettings from '../components/settings/Main_Settings';
import Help from '../components/settings/Help_Settings';
import Feedback from '../components/settings/Feedback_Settings';
import NavSettings from '../components/settings/Nav_Settings';

// Account pages
import ChangeUsername from '../components/settings/accountPages/Username';
import ChangeEmail from '../components/settings/accountPages/Email';
import ChangePassword from '../components/settings/accountPages/Password';

// Modal

// Components - imported

// Actions - imported
import { setPage, add_To_Recent_History } from '../actions/navActions';
import { setAlert } from '../actions/alertActions';
import { editUsername, changePassword, changeUserEmail } from '../actions/authActions';
import { toggleSideNav } from '../actions/navActions';

const initialState = {
    username: '',
    email: '',
    currentPassword: '',
    password: '',
    password2: '',
};

const Settings = ({ 
    goBack,
    setPage, 
    add_To_Recent_History,
    setAlert, 
    toggleSideNav, 
    editUsername, 
    changePassword, 
    changeUserEmail, 
    auth, 
    nav, 
    history 
}) => {
    
    // Screen Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Which page to display
    const [page, handlePage] = useState(null);

    // Input field data
    const [formData, setFormData] = useState(initialState);

    const [gender, setGender] = useState(null);

    // Edit Modal toggles
    const [usernameModal, setUsernameModal] = useState(false);
    const [emailModal, setEmailModal] = useState(false);
    const [phoneModal, setPhoneModal] = useState(false);
    const [passwordModal, setPasswordModal] = useState(true);

    // Get the current URL and extract the "redirect" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);

    useEffect(() => {

        // Update window width on resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Set the current page based to 'settings'
        setPage('settings');
        add_To_Recent_History(url.pathname);  // current url

        let pathname = window.location.pathname;

        const pathArray = pathname.split('/');

        const settingsPage = pathArray[pathArray.length - 1];

        handlePage(settingsPage);

        // Load user data
        if (!auth.loading && auth.user) {

            // Create a copy of the initial form data with user values
            const userData = { ...initialState };
            for (const key in auth.user) {
                if (key in userData) userData[key] = auth.user[key];
            }

            // Clear password fields
            userData.password = '';
            userData.password2 = '';

            setFormData(userData);
        }
        
        // Cleanup the event listener when the component unmounts
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());

    }, [window.location, auth.user]);

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };


    const { 
        username,  
        email, 
        currentPassword,
        password, 
        password2,
    } = formData;

    // Edit info logic

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value});

    const usernameTodo = (e) => {
        onSubmitUsername(e);
    }

    const emailTodo = (e) => {
        onSubmitEmail(e);
    }

    const passwordTodo = (e) => {
        onSubmitPassword(e);
    }

    const onSubmitUsername = async e => {
        e.preventDefault();

        if(auth.user) {
            const userId = auth.user_id; 

            editUsername({ 
                username,   
                userId
            });
            
            console.log('FRONT END ID')
            console.log(userId);
            console.log(username);
        }
    }

    const onSubmitEmail = async e => {
        e.preventDefault();

        if(auth.user) {
            const userId = auth.user_id; 


            changeUserEmail({ 
                email,
                userId
            });

            console.log('FRONT END ID')
            console.log(userId);
            console.log(email);
        }
    }

    const onSubmitPassword = async e => {
        e.preventDefault();

        if(auth.user) {
            const userId = auth.user_id; 

            if(currentPassword === '') {
                setAlert('Current password required', 'danger');
            } else if(password === '') {
                setAlert('Please enter a new password', 'danger');
            } else if(password2 === '') {
                setAlert('Must re-enter new password', 'danger');
            } else if(password !== password2) {
                setAlert('Passwords do not match', 'danger');
            } else {
                changePassword({ 
                    password, 
                    currentPassword,
                    userId 
                });
            }
        }
    }

    // Modal toggle logic

    const toggleUsernameModal = () => {
        setUsernameModal(!usernameModal);
    }

    const toggleEmailModal = () => {
        setEmailModal(!emailModal);
    }

    const togglePasswordModal = () => {
        setPasswordModal(!passwordModal);
    }

    // Determine mobile and tablet screen sizes
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    return (
        <Fragment>
            <div className="inbox__container">
                
                {/* Navigation */}
                {!isTablet && (
                    <NavSettings 
                        sideNav={nav.sideNav} 
                        handleSlideMenu={toggleSideNav} 
                        auth={auth} 
                        page={nav.page} 
                        history={history} 
                        goBack={goBack}
                    />
                )}

                {/* Account pages */}
                {page == 'account' && <MainSettings handleSlideMenu={toggleSideNav} formData={formData} toggleUsernameModal={toggleUsernameModal} toggleEmailModal={toggleEmailModal} togglePasswordModal={togglePasswordModal} history={history} auth={auth} />}

                {/* Individual account pages */}

                {page == 'screen_name' && <ChangeUsername username={username} usernameTodo={usernameTodo} onChange={onChange} history={history} auth={auth} goBack={goBack} />}

                {page == 'password' && <ChangePassword currentPassword={currentPassword} password={password} password2={password2} passwordTodo={passwordTodo} onChange={onChange} history={history} auth={auth} goBack={goBack} />}

                {page == 'email' && <ChangeEmail email={email} emailTodo={emailTodo} onChange={onChange} history={history} auth={auth} goBack={goBack} />}

                {/* End */}

                {/* Other setting pages */}

                {page == 'help' && <Help campus_name={nav.campus_name} handleSlideMenu={toggleSideNav} history={history} auth={auth} goBack={goBack} />}
                
            </div>
        </Fragment>
    )
}

Settings.propTypes = {
    auth: PropTypes.object.isRequired,
    nav: PropTypes.object.isRequired,
    setPage: PropTypes.func.isRequired,
    add_To_Recent_History: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    toggleSideNav: PropTypes.func.isRequired,
    editUsername: PropTypes.func.isRequired,
    changePassword: PropTypes.func.isRequired, 
    changeUserEmail: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    auth: state.auth,
    nav: state.nav,
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    setPage, 
    add_To_Recent_History,
    setAlert, 
    toggleSideNav, 
    editUsername, 
    changePassword, 
    changeUserEmail, 
    goBack
})(Settings);

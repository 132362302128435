import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Actions
import { changeProfileImg, editUserBio } from '../../../actions/authActions';
import { setAlert } from '../../../actions/alertActions';

// Switch Pages
import AddPhoto from './AddPhoto';
import AddBio from './AddBio';
import Confirm from './Confirm';


// Initial State 
const initialState = {
    bio: ''
};

const ProfileForm = ({ 
    changeProfileImg, 
    editUserBio, 
    auth, 
    setAlert
}) => {

    // Form Data state and setter
    const [formData, setFormData] = useState(initialState);

    // State variables for form transitions
    const [slideform1, setSlideForm1] = useState(false);
    const [slideform2, setSlideForm2] = useState(false);
    const [slideform3, setSlideForm3] = useState(false);
    const [slideform4, setSlideForm4] = useState(false);
    const [slideform5, setSlideForm5] = useState(false);
    const [slideform6, setSlideForm6] = useState(false);

    useEffect(() => {
        // Load profile data
        if (!auth.loading && auth.user) {
            const profileData = { ...initialState };
            for (const key in auth.user) {
                if (key in profileData) profileData[key] = auth.user[key];
            }

            setFormData(profileData);
        }
    }, [auth.user]);

    // --- Edit profile info logic ---

    const { bio } = formData;

    // Form input change handler
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value});

    // Triggered when the bio form is submitted
    const bioTodo = (e) => {
        onSubmitBio(e);
    }

    // Submit the bio form
    const onSubmitBio = async e => {
        e.preventDefault();

        if(auth.user) {
            const userId = auth.user_id; 

            // Call the action to edit user's bio
            editUserBio({ 
                bio,
                userId
            });

            console.log('FRONT END ID')
            console.log(userId);
            console.log('BIO')
            console.log(bio);
        }
    }

    return (
        <Fragment>
            <div style={{width:'100%'}} className="form-transition">

                {/* Add Photo Form */}
                <div id="transition-1" style={{width:'100%'}} className={!slideform1 ? "auth-form-container active" : "auth-form-container"}>
                    <AddPhoto slideform1={slideform1} setSlideForm1={setSlideForm1} user={auth.user} userId={auth.user_id} changeProfileImg={changeProfileImg} setAlert={setAlert} />
                </div>

                <div id="transition-2" style={{width:'100%'}} className={slideform1 ? "auth-form-container active" : "auth-form-container"}>
                    <div style={{width:'100%'}} className="form-transition">

                        {/* Add Bio Form */}
                        <div id="transition-1" style={{width:'100%'}} className={!slideform2 ? "auth-form-container active" : "auth-form-container"}>
                            <AddBio slideform1={slideform1} setSlideForm1={setSlideForm1} slideform2={slideform2} setSlideForm2={setSlideForm2} bio={bio} bioTodo={bioTodo} onChange={onChange} />
                        </div>

                        {/* Confirmation Slide - no inputs or actions here */}
                        <div id="transition-2" style={{width:'100%'}} className={slideform2 ? "auth-form-container active" : "auth-form-container"}>
                            <Confirm slideform2={slideform2} setSlideForm2={setSlideForm2} user={auth.user} />
                        </div>
                    </div>
                </div>
                
            </div>
        </Fragment>
    )
}

ProfileForm.propTypes = {
    // Prop type validation for authentication
    auth: PropTypes.object.isRequired,
    // Prop Type validation for function to change profile img
    changeProfileImg: PropTypes.func.isRequired,
    // Prop Type validation for function to edit user bio
    editUserBio: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    
    // Mapping the authentication state
    auth: state.auth
});

export default connect(mapStateToProps, { 

    // Connecting actions to the component
    changeProfileImg, 
    editUserBio,
    setAlert
})(ProfileForm);

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../../config/firebase-config';

// Redux
import { connect } from 'react-redux';

// Actions
import { signInWithGoogle } from '../../actions/authActions';
import { setAlert } from '../../actions/alertActions';

// Imgs
import google_logo from '../../utils/imgs/google_logo.jpg';

import { bannedEmails } from '../../utils/banned_emails/bannedEmails';

const GoogleBtn = ({
    signInWithGoogle,
    sidebar,
    nav: {
        campus_id,
        campus_name,
        campus_ext
    },
    setAlert
}) => {

    // Window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Listen for window resize event and update window width state
    useEffect(() => {

        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Clean up by removing event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Determine window size breakpoints
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1170;

    const handleGoogleSignIn = () => {
        console.log('STARTING GOOGLE SIGN IN')
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider).then(async (result) => {
            console.log('RETURNED USER')
            console.log(result.user);
            console.log(result.user.email);

            // --- Check if User is banned

            if(bannedEmails.includes(result.user.email)) {
                setAlert("Account has been banned", 'danger');
            } else {
                const {
                    displayName,
                    email,
                    phoneNumber,
                    photoURL,
                    emailVerified
                } = result.user;
    
                signInWithGoogle(
                    campus_id,
                    result, 
                    {
                        displayName,
                        email,
                        phoneNumber,
                        photoURL,
                        emailVerified
                    }
                )
            }
        }).catch((e) => {
            console.log('ERROR SIGNING IN WITH GOOGLE')
            console.error(e); // "oh, no!"
        })
    }

    return (
        <div onClick={handleGoogleSignIn} className='delivery_widget authPage grey_background'>
            <div style={{height: '90%', margin: '5px', padding: '3px', borderRadius: '50%', border: '1px solid #e6ecf0', boxSizing: 'border-box'}}>
                <img style={{objectFit: 'cover', height: '100%'}} src={google_logo} />
            </div>

            {sidebar && !isTablet && (
                <span style={{fontWeight: 'bold', fontSize: '16px', color: '#000'}}>
                    Log In with Google
                </span>
            )}

            {!sidebar && (
                <span style={{fontWeight: 'bold', fontSize: '16px', color: '#000'}}>
                    Continue with Google
                </span>
            )}
            
        </div>
    )
}

GoogleBtn.propTypes = {
    signInWithGoogle: PropTypes.func.isRequired,
    nav: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    nav: state.nav
})

export default connect(mapStateToProps, {
    
    // Connecting actions to the component
    signInWithGoogle,
    setAlert
})(GoogleBtn);

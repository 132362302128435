import React from 'react'
import PropTypes from 'prop-types'

const Readme = props => {
    return (
        <div className='readme_body'>
            <main>
        <h1 id="trivia-rest-api">Trivia REST API</h1>
<p>A <strong>Dockerized REST API</strong> built using <strong>Go Fiber</strong>, <strong>PostgreSQL</strong>, and <strong>Docker</strong> for managing trivia questions and answers. This project includes a reliable and scalable architecture using Go Fiber, manages structured data with PostgreSQL, and is deployed in a containerized environment using Docker.</p>
<p><img src="https://github.com/tunde262/trivia_rest_api_docker_postgres_go/blob/main/assets/thumbnail.png?raw=true" alt="thumbnail" /></p>
<h2 id="project-goal">Project Goal</h2>
<p>The goal of this project was to implement a scalable and containerized <strong>REST API Solution</strong> with a structured database schema. The API solves a real-world problem by efficiently managing trivia question and answer data.</p>
<h2 id="features">Features</h2>
<ul>
<li><strong>Go Fiber Framework</strong>: High-performance web framework for building RESTful APIs.</li>
<li><strong>PostgreSQL</strong>: Structured data storage with GORM for efficient ORM capabilities.</li>
<li><strong>Dockerized Deployment</strong>: Easily deploy and manage containers with <code>docker-compose</code>.</li>
<li><strong>Trivia Management</strong>: Create and retrieve trivia facts (questions and answers).</li>
<li><strong>Environment Variables</strong>: Secure and configurable database credentials using <code>.env</code> file.</li>
</ul>
<h2 id="technologies">Technologies</h2>
<ul>
<li><strong>Programming Language</strong>: Go (Golang)</li>
<li><strong>Web Framework</strong>: Go Fiber</li>
<li><strong>Database</strong>: PostgreSQL</li>
<li><strong>Containerization</strong>: Docker, Docker Compose</li>
<li><strong>Tools</strong>: Postman for API testing</li>
</ul>
<h2 id="installation">Installation</h2>
<h3 id="prerequisites">Prerequisites</h3>
<br/>
<ul>
<li>Docker &amp; Docker Compose installed on your system.</li>
<li>Go installed (for local development without Docker).</li>
</ul>
<h3 id="steps">Steps</h3>
<br/>
<ol>
<li><strong>Clone the Repository</strong>:</li>
</ol>
<br/>
<pre><code class="hljs bash language-bash">
    git <span class="hljs-built_in">clone</span> https://github.com/tunde262/trivia_rest_api_docker_postgres_go.git
   <br/><span class="hljs-built_in">cd</span> trivia_rest_api_docker_postgres_go
</code></pre>
<br/>
<ol start="2">
<li><b>Create an <code>.env</code> File</b>: Define the following environment variables in a <code>.env</code> file:</li>
</ol>
<br/>
<pre><code class="hljs bash language-bash">
   DB_USER=your_db_user<br />
   DB_PASSWORD=your_db_password<br />
   DB_NAME=your_db_name
</code></pre>
<br/>
<ol start="3">
<li><strong>Start Services</strong>: Use Docker Compose to build and start the services:</li>
</ol>
<br/>
<pre><code class="hljs bash language-bash">   docker-compose up --build</code></pre>
<br/>
4. <b>Access the API:</b>:
<br/>
<ul>
    <li>The API will run on <code>http://localhost:3000</code>.</li>
    <li>Use tools like Postman or <code>curl</code> to interact with the endpoints.</li>
</ul>

<h2 id="installation">API Endpoints</h2>

<ul>
    <li><b>GET /</b>: List all trivia facts.</li>
    <li><b>POST /fact</b>: Create a new trivia fact.</li>
</ul>

<h2 id="installation">Example Payload for <code>POST /fact</code></h2>
<pre><code class="hljs bash language-bash"> {"{"}<br />
    "question": "What is the capital of France?",<br />
    "answer": "Paris"<br />
    {"}"}
</code></pre>

<h2 id="installation">How It Works</h2>

<ol>
    <li>
        Database Connection:
        <ul>
            <li>A PostgreSQL database is initialized using credentials from the .env file.</li>
            <li>GORM is used for migrations and ORM capabilities.</li>
        </ul>
    </li>
    
    <li>
        REST API:
        <ul>
            <li>
            The <code>ListFacts</code> handler retrieves all trivia facts from the database.
            </li>
            <li>
            The <code>CreateFact</code> handler allows users to add new trivia facts.
            </li>
        </ul>
    </li>
    <li>
        Docker:
        <ul>
            <li>
                <code>web</code> service runs the Go Fiber app.
            </li>
            <li>
                <code>db</code> service sets up a PostgreSQL database.
            </li>
            <li>
                Both services are connected through Docker Compose.
            </li>
        </ul>
    </li>
</ol>

<h2 id="installation">Project Structure</h2>

<pre><code class="hljs bash language-bash"> 
  trivia_rest_api_docker_postgres_go/<br />
  ├── cmd/<br />
  │   └── main.go         # Entry point of the application<br />
  │   └── routes.go       # API route definitions<br />
  ├── database/<br />
  │   └── database.go     # Database connection and migrations<br />
  ├── handlers/<br />
  │   ├── facts.go        # Handlers for API routes<br />
  ├── models/<br />
  │   └── models.go       # Data models (e.g., Fact)<br />
  ├── docker-compose.yml  # Docker Compose configuration<br />
  ├── Dockerfile          # Docker image setup<br />
  └── .env.example        # Example .env file<br />
  </code></pre>
            </main>
        </div>
  )
}

Readme.propTypes = {}

export default Readme;

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';


const Alert = ({ alerts }) => {

    // Initialize the alertList variable
    let alertList;

    // Check if alerts exist and has items
    if(alerts !== null && alerts.length > 0) {
        // Map through the alerts and create an alert element for each
        alertList = alerts.map(alert => (
            <div className="alert alert-danger" role="alert">
                { alert.msg }
            </div>
        ))  
                    
    }

    // Render the alert container and the alertList
    return (
        <div className="alert-container">
            {alertList}
        </div>
        
    )
}

// Prop types for the Alert component
Alert.propTypes = {
    // Prop type validation for the array of alerts
    alerts: PropTypes.array.isRequired,
}

// Map the alerts from Redux state to the component props
const mapStateToProps = state => ({
    alerts: state.alert
});

// Connect the Alert component to the Redux store and export it
export default connect(mapStateToProps, null)(Alert);

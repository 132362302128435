import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Link } from 'react-router-dom';

import mixpanel from 'mixpanel-browser';

// Firebase
import { db } from '../config/firebase-config';
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, onSnapshot, orderBy, startAfter, limit } from 'firebase/firestore';

// Redux
import { connect } from 'react-redux';

//  Actions - imported
import { editUserName, editUserBio } from '../actions/authActions';
import { setPage, toggleAuthModal, add_To_Recent_History, toggleSideNav } from '../actions/navActions';
import { getUserByUsername, getUserById } from '../actions/authActions';
import { getPostsByUserId, getUserVideos, setPosts, set_ShowBottomSpinner } from '../actions/postActions';
import { getUserGames } from '../actions/gameActions';
import { getUserProjects } from '../actions/projectActions';

// wrapper layout
import Layout from '../components/layout/Layout';

// Modal
import Modal from '../components/modal/Modal';
import ModalContainer from '../components/modal/ModalContainer';

// Components
import { Button } from '@material-ui/core';
import Spinner from '../components/common/Spinner';
import ProfileHeader from '../components/Profile/ProfileHeader';
import Post from '../components/Post/Post';
import Icon_Block from './GamePage/Icon_Block';
import Project_Block from './GamePage/Project_Block';

// Icons
import PostAddIcon from '@material-ui/icons/PostAdd';
import AddIcon from '@material-ui/icons/Add';

// Edit profile - page
import ProfileEdit from '../components/Profile/ProfileEdit';

const Profile = ({ 
    setPage,
    toggleAuthModal,
    add_To_Recent_History,
    toggleSideNav,
    auth: { 
        user, 
        modalUser, 
        loading 
    }, 
    post,
    game,
    project,
    nav: {
        sideNav,
        page,
        campus_id
    },
    getPostsByUserId, 
    getUserVideos,
    getUserGames,
    getUserProjects,
    set_ShowBottomSpinner,
    setPosts,
    getUserById,
    getUserByUsername, 
    editUserName, 
    editUserBio,
    match,
    history
}) => {

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Load correct user 
    const [gotModalUser, setGotModalUser] = useState(false);

    // Check if user is verified
    const [checkedVerification, setCheckedVerification] = useState(false);

    // Posts
    const [postList, setPostList] = useState([]);
    const [gotPosts, setGotPosts] = useState(false);

    // Games
    const [gameList, setGameList] = useState([]);
    const [gotGames, setGotGames] = useState(false);

    // Projects
    const [projectList, setProjectList] = useState([]);
    const [gotProjects, setGotProjects] = useState(false);

    // Get Post Total 
    const [gotPostTotal, setGotPostTotal] = useState(false);
    const [totalPosts, setTotalPosts] = useState(0);

    // Scroll direction - for displaying top nav bar
    const [scrollDirection, setScrollDirection] = useState("up");
    const [oldScrollY, setOldScrollY] = useState(window.scrollY);

    // Temporarily disable to load more function to avoid duplicate fetch requests
    const [canLoadMore, setCanLoadMore] = useState(true);
    

    // Modal toggles
    const [messageModal, setMessageModal] = useState(false);

    // Get the "show" and "redirect" parameters from the URL
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    const filter = url.searchParams.get("show");
    const showMessageModal = url.searchParams.get("redirect");

    const SPACE_HEIGHT = 250; // Hieght of className 'no-rides' which is a spacer to make sure user can always scroll down

    // Firebase collection ref
    const postsCollectionRef = collection(db, "posts");

    // Determine mobile and tablet screen sizes
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    useEffect(() => {
        
        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Cleanup the event listener when the component unmounts
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    useEffect(() => {

        // Set the current page to 'profile'
        setPage('profile');
        add_To_Recent_History(url.pathname);  // current url

        getUserByUsername('tundeadepitan');

        // Get the user by username from the route parameter
        // if(match.params.username) {
        //     if(match.params.username !== modalUser?.username) { // This conditional prevents the program from calling getUserByUsername() twice
        //         getUserByUsername(match.params.username);
        //     }
        // } else {
        //     if(!loading && user) {
        //         getUserByUsername(user.username);
        //     }
        // }
    }, [match.params.username, user])

    useEffect(() => {
        setGotPosts(false);
        setGotGames(false);
        setGotProjects(false);
        console.log('CALLING FETCH USERS')
        fetchUserPosts();

    }, [modalUser, filter]);

    useEffect(() => {

        renderPostList();

    }, [post.posts, canLoadMore]);

    useEffect(() => {

        renderGameList();

    }, [game.games, canLoadMore]);

    useEffect(() => {

        renderProjectList();

    }, [project.projects, canLoadMore]);


    // --- Profile TABS logic ---

    const fetchUserPosts = () => {

        if(modalUser) {
            if(filter) {

                // Load posts with photos if on the profile's 'photos' tab
                if(filter === 'videos') {

                    getUserVideos(modalUser._id, campus_id)
                    setGotPosts(true);
                    
                } else if(filter === 'games') {

                    getUserGames(modalUser._id, campus_id);
                    setGotGames(true);
                    
                } else if(filter === 'projects') {

                    getUserProjects(modalUser._id, campus_id);
                    setGotProjects(true);
                    
                }
                
            } else {

                // getPostsByUserId(modalUser._id, campus_id);
                // setGotPosts(true);

                getUserProjects(modalUser._id, campus_id);
                setGotProjects(true);
            }

            // Update the user's total posts
            if(modalUser.totalPosts) {
                setTotalPosts(modalUser.totalPosts);
            }
        }
    }

    // --- END: Profile TABS ---

    const toggleEdit = () => {
        // setEditMode(!editMode);
        window.location.href = "/profile/edit";
        // history.push('/profile/edit')
    } 

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Infinite scroll AND handle top nav bar display
    const handleScroll = (e) => {
        const { offsetHeight, scrollTop, scrollHeight} = e.target

        const newScrollY = offsetHeight + scrollTop;

        // --- Handle top nav bar display
        if(isMobile) {
            if(oldScrollY < newScrollY){

                // scrolling down - hide top nav
                setScrollDirection("down");
    
                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.remove("active");
                }
                
            } else {
    
                // scrolling up - show top nav
                setScrollDirection("up");
    
                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.add("active");
                }
            }
        }
        // --- END Handle top nav bar display

        // update the scroll position for future comparison
        setOldScrollY(newScrollY)

        // Check if the user has scrolled to the bottom of the content and conditions for loading more posts are met
        if (newScrollY >= scrollHeight - (SPACE_HEIGHT+1) && !post.show_bottom_spinner && !post.no_more_posts && post.posts.length > 0 && canLoadMore) {
            console.log('scrolling');
            loadMore(post.last_page_doc);
        }
    }

    const loadMore = (lastPage_doc) => {
        console.log("LOADING MORE");
        console.log("last", lastPage_doc);

        // Temporarily disable to load more function to avoid duplicate fetch requests
        setCanLoadMore(false);

        // Show spinner
        set_ShowBottomSpinner(true);

        if(filter) {

            // Load posts with videos if on the profile's 'videos' tab
            if(filter === 'videos') {

                getUserVideos(modalUser._id, campus_id, lastPage_doc)

            } else if(filter === 'games') {

                getUserGames(modalUser._id, campus_id, lastPage_doc)

            }
            
            
        } else {
            getPostsByUserId(modalUser._id, campus_id, lastPage_doc)
        }
        
        // Re-enable the load more function after 1 second
        setTimeout(() => {
            setCanLoadMore(true);
        }, 1000);
    }

    const renderPostList = async () => {
        setPostList([]);

        if((post.posts === null && loading) || !gotPosts) {
            setPostList([(
                <div className="no-rides">
                    <Spinner />
                </div>
            )])
        }
        else { 
            if(post.posts.length > 0) {
                post.posts.map(post_doc => {
                    const renderPostRecursive = (postComments, parentPost) => {
                        
                        const lastComment = postComments[postComments.length - 1]; // Get the last comment
    
                        return (
                            <Fragment>
                                <Post key={lastComment._id} postDoc={lastComment} parentPost={parentPost} />
    
                                {/* Recursive call to render comments of comments */}
                                {lastComment.commentsOfComments && lastComment.commentsOfComments.length > 0 && (
                                    <div className="comments-of-comments">
                                    {renderPostRecursive(lastComment.commentsOfComments, lastComment)}
                                    </div>
                                )}
                            </Fragment>
                        );
    
                    };
    
                    if(post_doc.commentsOfComments && post_doc.commentsOfComments.length > 0) {
                        setPostList(postList => [...postList, (
                            <div className='post_container'>
                                <Post key={post_doc._id} postDoc={post_doc} />
    
                                {renderPostRecursive(post_doc.commentsOfComments, post_doc)}
                            </div>
                        )])
                    } else {
                        setPostList(postList => [...postList, (
                            <Post key={post_doc._id} postDoc={post_doc} />
                        )])
                    }
                }) 
            } else {
                setPostList(postList => [...postList, (
                    <div className="no-rides">
                        <h1>No Posts</h1>
                    </div>
                )])
                
            }
        } 

        setGotPosts(true);
    }

    const renderGameList = async () => {
        setGameList([]);

        if((game.games === null && game.loading) || !gotGames) {
            setGameList([(
                <div className="no-rides">
                    <Spinner />
                </div>
            )])
        }
        else { 
            if(game.games.length > 0) {
                game.games.map(game_doc => {
                    setGameList(gameList => [...gameList, (
                        <Icon_Block key={game_doc._id} detail_Obj={game_doc} />
                    )])
                }) 
            } else {
                setGameList(gameList => [...gameList, (
                    <div className="no-rides">
                        <h1>No Games</h1>
                    </div>
                )])
                
            }
        } 

        setGotGames(true);
    }

    const renderProjectList = async () => {
        setProjectList([]);

        if((project.projects === null && project.loading) || !gotProjects) {
            setProjectList([(
                <div className="no-rides">
                    <Spinner />
                </div>
            )])
        }
        else { 
            if(project.projects.length > 0) {
                project.projects.map(project_doc => {
                    setProjectList(projectList => [...projectList, (
                        <Project_Block key={project_doc._id} detail_Obj={project_doc} />
                    )])
                }) 
            } else {
                setProjectList(projectList => [...projectList, (
                    <div className="no-rides">
                        <h1>No Projects</h1>
                    </div>
                )])
                
            }
        } 

        setGotProjects(true);
    }

    // Initialize Mixpanel and track Page View
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Profile View");
    }

    // Check if the code is running in production and Mixpanel event hasn't been sent yet
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {
        
        // Initialize Mixpanel and track the event
        handleMixpanel();

        // Set the flag to indicate that Mixpanel event has been sent
        setSentMixpanel(true);
    }

    let isMyProfile = false;

    // Check if the current highlighted navigation is 'profile'
    if(user && user._id === 'pjaWd3NhsqNtC5fw2PRROQdf28F2') {
        isMyProfile = true;
    } else {
        isMyProfile = false;
    }

    return (
        <Fragment>
            <Layout handleScroll={handleScroll} sideNav={sideNav} handleSlideMenu={toggleSideNav} page="profile" user={modalUser} totalPosts={totalPosts}>

                {!modalUser ? (
                    <div className="no-post">
                        <h1>This page doesn’t exist. </h1>
                        <p>Try searching for something else.</p>
                        <Button  
                            onClick={() => window.location ='/search'}
                            className="noChats__btn"
                        >
                            Search
                        </Button>
                    </div>
                ) : (
                    <Fragment>
                        {/* Render the profile header */}
                        <ProfileHeader toggleEdit={toggleEdit} />
                        
                        {/* Render the post list */}
                        {(filter === 'videos') && (
                            <Fragment>
                                <div style={{width: '100%', height: 'fit-content', display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridAutoRows: 'min-content', borderBottom: '1px solid #ddd'}}>
                                
                                    <h1>vids</h1>

                                    {/* {(!post.no_more_posts && post.posts.length !== 0) && (<Empty_Card last_page_doc={post.last_page_doc} page={"gallery"} loadMore={loadMore} setCanLoadMore={setCanLoadMore} canLoadMore={canLoadMore} />)} */}
                                </div>
                            </Fragment>
                        )}

                        {(filter === 'games') && (
                            <Fragment>
                                <div style={{width: '100%', height: 'fit-content', display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridAutoRows: 'min-content', borderBottom: '1px solid #ddd'}}>
                                
                                    {gameList}

                                </div>

                                {/* Show a spinner at the bottom if more posts are being loaded */}
                                {game.show_bottom_spinner && !game.no_more_posts && <Spinner />}
                            </Fragment>
                            
                        )}

                        {(filter !== 'videos' && filter !== 'games' && filter !== 'projects' ) && (
                            <Fragment>
                                <div className="project_box_container" style={{padding:'20px'}}>
                                
                                    {projectList}

                                </div>

                                {/* Show a spinner at the bottom if more posts are being loaded */}
                                {project.show_bottom_spinner && !project.no_more_posts && <Spinner />}
                            </Fragment>
                            
                        )}

                        {/* {(filter !== 'videos' && filter !== 'games' && filter !== 'projects' ) && (
                            <Fragment>
                                {postList}

                                Show a spinner at the bottom if more posts are being loaded
                                {post.show_bottom_spinner && !post.no_more_posts && <Spinner />}
                            </Fragment>
                            
                        )} */}

                        
                        
                        {/* Render a message when there are no more posts to load */}
                        <div className="no-rides">{(post.no_more_posts && post.posts.length !== 0) && "You've reached the end."}</div>
                        
                        {/* Render a button to create a new post for tablet devices */}
                        {!loading && user && isMyProfile ? (
                            isMobile && (
                                <Link to={`/create/post?goBack=${page}`} id="fixed-button" className="show">
                                    <AddIcon />
                                </Link>
                            )
                        ) : null}
                    </Fragment>
                )}

            </Layout>
        </Fragment>
    )
}

Profile.propTypes = {
    setPage: PropTypes.func.isRequired,
    toggleAuthModal: PropTypes.func.isRequired,
    add_To_Recent_History: PropTypes.func.isRequired,
    toggleSideNav: PropTypes.func.isRequired,
    getUserByUsername: PropTypes.func.isRequired,
    getPostsByUserId: PropTypes.func.isRequired,
    getUserVideos: PropTypes.func.isRequired,
    set_ShowBottomSpinner: PropTypes.func.isRequired,
    getUserGames: PropTypes.func.isRequired,
    getUserProjects: PropTypes.func.isRequired,
    setPosts: PropTypes.func.isRequired,
    getUserById: PropTypes.func.isRequired,
    editUserName: PropTypes.func.isRequired, 
    editUserBio: PropTypes.func.isRequired, 
    auth: PropTypes.object.isRequired,
    post: PropTypes.object.isRequired,
    nav: PropTypes.object.isRequired,
    game: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    auth: state.auth,
    post: state.post,
    nav: state.nav,
    game: state.game,
    project: state.project
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    setPage,
    toggleAuthModal,
    add_To_Recent_History,
    toggleSideNav,
    getUserByUsername,
    getPostsByUserId,
    getUserVideos,
    getUserGames,
    getUserProjects,
    set_ShowBottomSpinner,
    setPosts,
    getUserById,
    editUserName, 
    editUserBio,
})(Profile);

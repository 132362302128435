import React, {  useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// A functional component representing a notification in the alert popup
const Notification = ({ alert, removeAlert, last_post_added }) => {
    
    // State variable for tracking the width of the progress bar
    const [width, setWidth] = useState(0);

    // State variable for storing the interval ID of the timer
    const [intervalID, setIntervalID] = useState(null);

    const [showAlert, setShowAlert] = useState(true);

    // Function to start the timer for increasing the width of the progress bar
    const handleStartTimer = () => {
        const id = setInterval(() => {
            setWidth(prev => {
                if(prev < 100) {

                    return prev + 0.5;
                }

                return prev;

            })
        }, 12);

        setIntervalID(id)
    }

    // Function to pause the timer by clearing the interval
    const handlePauseTimer = () => {
        clearInterval(intervalID)
    };

    useEffect(() => {

        // Start the timer when the component mounts
        handleStartTimer();
    }, [])

    return (
        <div 
            // onMouseEnter={handlePauseTimer} 
            // onMouseLeave={handleStartTimer} 
            className={
                `alert-popup showAlert show 
                ${alert.alertType === 'success' && 'success'}
                ${alert.alertType === 'okay' && 'okay'}
                ${alert.alertType === 'danger' && 'danger'}`
            }
        >
            {/* Alert Notification text */}
            <span className="msg">
                { alert.msg } 
                {(last_post_added && alert.msg.toLowerCase().includes('post was sent')) && (
                    <a style={{textDecoration:'underline', marginLeft:'5px', fontWeight:'bold', color:'#fff'}} href={`/post/${last_post_added}`}>View</a>
                ) }

                {(alert.msg.toLowerCase().includes('added to bag')) && (
                    <a style={{textDecoration:'underline', marginLeft:'5px', fontWeight:'bold', color:'#fff'}} href={`/cart`}>View Bag</a>
                ) }
            </span>
            {/* <div className="close-btn">
                <span className="fas fa-times"></span>
            </div> */}
            <div className="alert-popup__bar" style={{width: `${width}%`}} />
        </div>
        
    )
}

Notification.propTypes = {

}

export default Notification;

import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types'

// Redux
import { connect } from 'react-redux';

// Actions
import { setPage, togglePostModal } from '../../actions/navActions';

// Components - imported
import { Button } from '@material-ui/core';
// Icons -imported
import AddIcon from '@material-ui/icons/Add';

const Sidebar = ({
    setPage,
    togglePostModal,
    auth: { 
        user, 
        modalUser,
        isAuthenticated, 
        loading 
    }, 
    nav: { 
        page,
    },
    isMobile,
    isTablet
}) => {

    // State to determine highlighted sidebar option
    const [navHighlight, setNavHighlight] = useState(null);

    // --- Set the navigation highlight based on the current page
    useEffect(() => {
        if(page === 'home') {
            setNavHighlight('home');
        }

        if(page === 'profile') {
            setNavHighlight('profile');
        }

        if(page === 'settings') {
            setNavHighlight('settings');
        }

    }, [page]);

    // Get User info
    let username = null;

    // Check if the user is logged in and set the username
    if(user) {
        username = user.username;
    }

    let isMyProfile = false;

    // Check if the current highlighted navigation is 'profile'
    if(navHighlight === "profile") {
        if(user && modalUser && user._id === 'pjaWd3NhsqNtC5fw2PRROQdf28F2') {
            isMyProfile = true;
        } else {
            isMyProfile = false;
        }
    }

    let user_name;

    if(!loading && isAuthenticated) {
        user_name = `${user.first_name} ${user.last_name && user.last_name}`
    }

    // --- ImageKit (img optimization service) ---
    
    let optimized_profile_img_path;

    if(user && user.img) {
   
        // Replace the firebase URL with ImageKit.io URL-endpoint
        optimized_profile_img_path = user.img.replace("https://firebasestorage.googleapis.com","https://ik.imagekit.io/pba3w2st28/");
    }

    // --- END: ImageKit (img optimization service) ---

    return (
        <div className="sidebar" style={{display:'flex', position:'relative', flexDirection: 'column'}}>
            {/* <TwitterIcon className="sidebar__twitterIcon" /> */}


            {/* Conditional rendering for create post button */}

            {!loading && isAuthenticated && (
                <Fragment>
                    {isAuthenticated && isMyProfile && (
                        <Button onClick={togglePostModal} variant="outlined" className="sidebar__tweet compose" fullWidth>
                            {!isTablet ? 'Create Post' : <AddIcon />}
                        </Button>
                    )}
                </Fragment>
            )}

        </div>
    )
}

Sidebar.propTypes = {
    auth: PropTypes.object.isRequired, // Object prop representing the authentication data for the user
    nav: PropTypes.object.isRequired, // Prop: Object representing navigation state
    setPage: PropTypes.func.isRequired, // Function prop to set the current page
    togglePostModal: PropTypes.func.isRequired, // Function prop to toggle the post modal
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    auth: state.auth,
    nav: state.nav
})

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    setPage, 
    togglePostModal,
})(Sidebar);

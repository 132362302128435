import React, { Fragment, useEffect, useState } from 'react';

// Components - material UI
import { Button } from '@material-ui/core';

// Icons - material UI
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const Username = ({
    username,
    usernameTodo,
    onChange,
    auth,
    history,
    goBack
}) => {

    // Screen Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {

        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Cleanup the event listener when the component unmounts
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Determine mobile and tablet screen sizes
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    return (
        <div className="settings">

            {/* Header */}
            <div className="settings__header">

                {/* Back Btn Icon & Badge Value */}
                <div onClick={goBack} className="profile__btnContainer">
                    <ArrowBackIcon />
                </div>

                {/* Title */}
                <div className="settings__headerText">
                    <h3>
                        Change username
                    </h3>
                </div>
            </div>


            {/* Main Content */}
            <div className="settings__body">
                <div style={{width: '100%', boxSizing:'border-box', padding:'10px', display:'flex', justifyContent:'center', borderBottom:'1px solid #e6ecf0'}}>
                    
                    {/* Username Input */}
                    <input
                        type="username"
                        placeholder="Username"
                        className="input"
                        name="username"
                        value={username}
                        onChange={e => onChange(e)}
                        maxlength="50"
                        style={{width: '100%'}}
                    />
                </div>

                {/* Submit */}
                <div style={{width: '100%', boxSizing:'border-box', padding:'0 10px', display:'flex', justifyContent:'flex-end'}}>
                    <Button 
                        disabled={username === auth.user.username}
                        variant="outlined" 
                        className={`sidebar__tweet save ${username === auth.user.username ? 'disabled' : ''}`} 
                        onClick={usernameTodo}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default Username;

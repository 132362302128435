import React, { Fragment, useEffect, useState } from 'react';

import mixpanel from 'mixpanel-browser';

// components - material UI
import { Button } from '@material-ui/core';

// icons - material UI
import InstagramIcon from '@material-ui/icons/Instagram';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const Projects_Page = ({ 
    handleSlideMenu, 
    auth,
    goBack
}) => {

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Screen Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {

         // Add event listener for window resize
         window.addEventListener('resize', () => handleWindowSizeChange());

         // Cleanup the event listener when the component unmounts
         return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Determine mobile and tablet screen sizes
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    // Initialize Mixpanel and track settings Help page view
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Settings Help Page View");
    }

    // Check if the code is running in production and Mixpanel event hasn't been sent yet
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {
        
        // Initialize Mixpanel and track the event
        handleMixpanel();

        // Set the flag to indicate that Mixpanel event has been sent
        setSentMixpanel(true);
    }

    return (
        <div className="settings">

            {/* Header */}
            <div className="settings__header more_page_header">
                {/* Back Btn Icon & Badge Value */}
                <div onClick={goBack} className="profile__btnContainer">
                    <ArrowBackIcon />
                </div>

                {/* Title */}
                <div onClick={handleSlideMenu} className="settings__headerText">
                    <h3>
                        About Me
                    </h3>
                </div>
            </div>

            {/* Main Content */}
            <div className="settings__body terms">
                <div className="terms-of-service" style={{paddingTop:'20px'}}> 
                    <p className="MuiTypography-root MuiTypography-body1 MuiTypography-colorTextPrimary">Last updated: March 19, 2025</p>
                    <div>
                        <p style={{marginBottom:'12px', marginTop:'0', lineHeight:1.5, fontFamily: 'Calibri,Candara,Segoe,segoe ui,Optima', }}>
                            Hello! I'm Tunde. As a kid I loved sci-fi and technology, so much so that I taught myself how to code by watching hundreds of YouTube tutorials.
                        </p>
                        <p style={{marginBottom:'12px', marginTop:'0', lineHeight:1.5, fontFamily: 'Calibri,Candara,Segoe,segoe ui,Optima', }}>
                            In 2024, I graduated with a degree in Computer Science from the University of Oklahoma, where I built and launched a small <a href="https://www.tunde.app/project/VQ4F6DSSgwx0xdfY4iFS" target='_blank'>tech-enabled business</a> aimed at college students and scaled it to over 2,000 active users. What started as a simple REST API and client system evolved into a much more complex infrastructure. I did everything from managing code quality, deployments, and system reliability, to implementing various CI/CD pipelines using GitHub Actions. 
                        </p>
                        <p style={{marginBottom:'12px', marginTop:'0', lineHeight:1.5, fontFamily: 'Calibri,Candara,Segoe,segoe ui,Optima', }}>
                            I recently completed a pre-employment training program, to become a backend developer, for a company called Revature. During which I completed tests on advanced Java concepts, relational databases, SQL, and RESTful API construction. One new and very important technology I learned during the training was the Spring framework in Java, which I intend to keep working with in the future because it speeds up software development and is great for building loosely coupled systems.
                        </p>
                        <p style={{marginBottom:'12px', marginTop:'0', lineHeight:1.5, fontFamily: 'Calibri,Candara,Segoe,segoe ui,Optima', }}>
                        I can code in Java, Python, and JavaScript. I am also familiar with C, and C++.
                        </p>
                        <p style={{marginBottom:'12px', marginTop:'0', lineHeight:1.5, fontFamily: 'Calibri,Candara,Segoe,segoe ui,Optima', }}>
                        In the short term I hope to gain more experience as a software engineer. In the long term, I aspire to become a top-level engineer managing a large team that develops innovative solutions.
                        </p>
                        <p style={{marginBottom:'12px', marginTop:'0', lineHeight:1.5, fontFamily: 'Calibri,Candara,Segoe,segoe ui,Optima', }}>
                            Check out my: <br/>
                            <a href="https://www.youtube.com/@tunde-tv" target='_blank'>YouTube</a><br/>
                            <a href="https://www.linkedin.com/tunde-adepitan-31bab8146" target='_blank'>LinkedIn</a><br/>
                            <a href="https://www.instagram.com/tunde.tv/" target='_blank'>Instagram</a><br/>
                            <a href="https://x.com/tunde_tv" target='_blank'>Twitter</a><br/>
                            <a href="https://www.github.com/tunde262" target='_blank'>Github</a>
                        </p>
                        {/* <p style={{marginBottom:'12px', marginTop:'0', lineHeight:1.5, fontFamily: 'Calibri,Candara,Segoe,segoe ui,Optima', }}>
                            This all started 8 years ago, when I was unhappy with my life and decided to design a new one. Since then, I’ve worked remotely while traveling the world. I share much of my life in the open, although it’s often a (very) delayed feed. 🙃
                        </p>
                        <ol style={{paddingLeft:'0.7em'}}>
                            <p style={{marginBottom:'12px', marginTop:'0', lineHeight:1.5, fontFamily: 'Calibri,Candara,Segoe,segoe ui,Optima', }}>
                                Here are a few other fun highlights:
                            </p>
                            <ul style={{listStyleType: 'disc'}}>
                                <li style={{paddingBottom:'12px', fontFamily: 'Calibri,Candara,Segoe,segoe ui,Optima', }}>
                                    Occasionally my Twitter addiction results in someone famous following me. Last month it was Hannibal Buress.
                                </li>
                                <li style={{paddingBottom:'12px', fontFamily: 'Calibri,Candara,Segoe,segoe ui,Optima', }}>
                                    I wrote my book in 7 weeks and has now sold over $250k.
                                </li>
                                <li style={{paddingBottom:'12px', fontFamily: 'Calibri,Candara,Segoe,segoe ui,Optima', }}>
                                    Way back when, I did my degree in chem eng.
                                </li>
                                <li style={{paddingBottom:'12px', fontFamily: 'Calibri,Candara,Segoe,segoe ui,Optima', }}>
                                    I've been known to sleep eat. Yes, it’s a thing.
                                </li>
                                <li style={{paddingBottom:'12px', fontFamily: 'Calibri,Candara,Segoe,segoe ui,Optima', }}>
                                    I’ve visited over 50 countries.
                                </li>
                                <li style={{paddingBottom:'12px', fontFamily: 'Calibri,Candara,Segoe,segoe ui,Optima', }}>
                                    I love spreadsheets, electric bikes, flying, untranslatable wods, and dill pickles!
                                </li>
                            </ul>
                        </ol> */}
                    </div>
                </div>
            </div>
            <div className="no-rides" />
        </div>
    )
}

export default Projects_Page;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';

// Redux
import { connect } from 'react-redux';

// components - imported
import { Avatar } from '@material-ui/core';
import DefaultAvatar from 'react-avatar';
import Verification_Badge from '../common/Verification_Badge';

// Icons - imported

// DraftJS
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

const ModalPost = ({ post, auth }) => {

    // Window Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // --- Convert DraftJS text to HTML text

    // Tracks whether the text has been converted
    const [gotText, setGotText] = useState(false);
    // Stores the converted text
    const [textState, setTextState] = useState(null);

    useEffect(() => {

        // Add an event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update the window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Converts the DraftJS text to HTML and sets the converted text
    if (post && !gotText) {
        console.log('TEXT BLOCK')

        let unRawState;

        if(post.text !== null) {
            unRawState = EditorState.createWithContent(
                convertFromRaw(JSON.parse(post.text))
            );
        
            console.log(unRawState)

            console.log(draftToHtml(convertToRaw(unRawState.getCurrentContent())))
            
            const unRawDescription = draftToHtml(convertToRaw(unRawState.getCurrentContent()));

            setTextState(unRawDescription);
        }

        
        // let editorState = post.text
        // const rawContentState = editorState.getCurrentContent();
 
        // const markup = draftToHtml(
        //     rawContentState
        // );

        // console.log(markup)

        // Marks that the text conversion has been performed
        setGotText(true)
    }

    // Determine window size breakpoints
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    
    return (
        // Render the modal post component 
        <div className="modalPost">

            {/* Avatar - left side 3;7 */}
            <div onClick={() => window.location = `/${post.user.username}`} className="modalPost__avatar">

                {post.user && post.user.img ? (
                    <Avatar src={post.user.img} />
                ) : (
                    <DefaultAvatar name={post.user && `${post.user.first_name} ${post.user.last_name}`} />
                )}
            </div>

            {/* Modal Post Main Body - right side 3:7 */}
            <div className="modalPost__body">

                {/* Modal Post Header */}
                <div className="modalPost__header">
                    <div className="modalPost__headerText">
                        <h3>

                            {/* Modal Post Author name */}
                            <span>
                                <Link to={`/${post.user.username}`}>
                                    <span>{post.username ? post.username : null} {' '}</span>
                                    <Verification_Badge user={post.user} /> <span>{/*TODO: GET RID OF THIS SPAN*/}</span> 
                                </Link>
                            </span>

                            {/* Post Timestamp */}
                            <span className="modalPost__headerSpecial">
                                <a href={`/post/${post._id}`}>
                                    {formatDistanceToNow(post.date)} ago
                                </a>
                            </span>
                        </h3>
                    </div>

                    {/* Modal Post Text */}
                    <div className="modalPost__headerDescription">
                        {/* <p>{post.text ? post.text : null}</p> */}

                        {textState !== null && (<div dangerouslySetInnerHTML={{__html: textState}} />)}
                    </div>
                </div>
            </div>
        </div>
    )
}

ModalPost.propTypes = {

}

const mapStateToProps = state => ({

});

// Connect the ModalPost component to the Redux store and export it
export default connect(mapStateToProps, null)(ModalPost);

import { setAlert  } from './alertActions';

import { db, auth, storage } from '../config/firebase-config';
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, serverTimestamp, orderBy, increment, startAfter, limit, onSnapshot } from 'firebase/firestore';
import { uploadBytes, ref, getDownloadURL, uploadBytesResumable, deleteObject } from 'firebase/storage';

// DraftJS
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";

import {
    PROJECTS_LOADING,
    GET_PROJECTS,
    SET_PROJECTS,
    SET_LAST_PROJECTS,
    GET_PROJECT,
    UPDATE_PROJECT_LIKES,
    ADD_PROJECT,
    DELETE_PROJECT,
    PROJECT_COMMENTS_LOADING,
    GET_PROJECT_COMMENTS,
    SET_PROJECT_COMMENTS,
    ADD_PROJECT_COMMENT,
    REMOVE_PROJECT_COMMENT,
    CLEAR_PROJECTS,
    CLEAR_PROJECT,
    PROJECT_ERROR,
    UPDATE_LAST_PROJECT_ADDED,
    PROJECT_UPLOADING,
    PROJECT_UPLOADING_IMGS,
    SET_SHOW_BOTTOM_SPINNER_PROJECT, 
    SET_LAST_PAGE_DOC_PROJECT, 
    SET_NO_MORE_PROJECTS
} from './types';

// Reference to different collections in Firestore
const projectsCollectionRef = collection(db, "projects");
const postsCollectionRef = collection(db, "posts");


const removeHtmlTags = (str) => {
    if ((str===null) || (str===''))
        return false;
    else
        str = str.toString();
          
    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace( /(<([^>]+)>)/ig, '');
}


// Get single Post by id
export const getProjectById = id => async dispatch => {
    dispatch(setProjectsLoading());
    try {

        // Create a reference to the specified post document in the "projects" collection
        const docRef = doc(db, 'projects', id)

        // Retrieve the document with the specified ID from the "projects" collection
        const projectDoc = await getDoc(docRef);

        console.log('GOT PROJECT BY ID');
  
        if(projectDoc.data()) {
            // Dispatch an action of type GET_PROJECT with the post data and ID as the payload
            dispatch({
                type: GET_PROJECT,
                payload: {
                    ...projectDoc.data(),
                    _id: id
                }
            });
        } else {
            dispatch({
                type: GET_PROJECT,
                payload: null
            });

            dispatch(setAlert('Sorry, that project has been deleted.', 'okay'));
        }
    } catch (err) {

        // Dispatch an action of type PROJECT_ERROR with an error message and status code as the payload
        dispatch({
            type: PROJECT_ERROR,
            payload: { msg: "something went wrong", status: 500 }
        });
        
        // Log the error to the console
        console.log(err);
    }
}

export const getUserProjects = (user_id, campus_id, lastPageDoc) => async dispatch => {

    if(!lastPageDoc) dispatch(setProjectsLoading());

    console.log('GETTING GALLERY PROJECT');
    
    try {
        console.log('QUERYING GALLERY PROJECT');

        // Create a query to retrieve projects where the user ID matches the provided ID, 
        // the image count is greater than or equal to 1, 
        // ordered by image count and creation date in descending order

        let q;

        if(lastPageDoc) {
            // Create a new query to retrieve projects starting at the last visible document
            q = query(projectsCollectionRef, where("user._id", "==", user_id), orderBy('createdAt', 'desc'), startAfter(lastPageDoc || 0), limit(10));
          
        } else {
            // Create a query to retrieve projects 
            q = query(projectsCollectionRef, where("user._id", "==", user_id), orderBy('createdAt', 'desc'), limit(10));
        }

        // Execute the query and retrieve the query snapshot
        onSnapshot(q, async (snapshot) => {
            console.log('UPDATING USER PROJECTS...');
            const tempProjectList = snapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

            let lastDocument = tempProjectList[tempProjectList.length - 1];

            if(lastDocument) {

                // Create a reference to the specified project document in the "projects" collection 
                const docRef = doc(projectsCollectionRef, lastDocument._id);
    
                // Retrieve the document with the specified ID from the "projects" collection
                const projectDoc = await getDoc(docRef);
    
                // Set the last visible document for the next load
                dispatch({
                    type: SET_LAST_PAGE_DOC_PROJECT,
                    payload: projectDoc
                });
    
                dispatch(set_ShowBottomSpinner(false));
            }

            // Dispatch an action of type SET_PROJECTS or SET_LAST_PROJECT with the posts as the payload
            if(tempProjectList.length < 9 && lastPageDoc) {
                dispatch({
                    type: SET_LAST_PROJECTS,
                    payload: tempProjectList
                });
            } else {
                dispatch({
                    type: SET_PROJECTS,
                    payload: tempProjectList
                });
            }
            
        })
    } catch (err) {
        console.log('ERROR!!!')
        console.log(err)

        // Dispatch an action of type SET_PROJECTS with null as the payload in case of an error
        dispatch({
            type: SET_PROJECTS,
            payload: null
        })
    }
}

// filter the projects by category
export const getCategoryProjects = (category) => async dispatch => {
    dispatch(setProjectsLoading());
    console.log('GETTING CATEGORY PROJECTS');
    try {
        console.log('QUERYING CAT PROJECTS');

        // Create a query to retrieve projects where the category matches the provided category
        const q = query(projectsCollectionRef, where("category", "==", category));

        // Execute the query and retrieve the query snapshot
        const querySnapshot = await getDocs(q);

        // Map through the document snapshots in the query snapshot and extract the data and ID for each post
        const categoryProjects = querySnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        // Dispatch an action of type GET_PROJECTS with the category posts as the payload
        dispatch({
            type: GET_PROJECTS,
            payload: categoryProjects
        });
    } catch (err) {
        console.log('ERROR!!!')
        console.log(err)

        // Dispatch an action of type SET_PROJECTS with null as the payload in case of an error
        dispatch({
            type: SET_PROJECTS,
            payload: null
        })
    }
}

// Get projects user liked
export const getLikedProjects = (userId, campus_id, lastPageDoc) => async dispatch => {

    if(!lastPageDoc) dispatch(setProjectsLoading());
    
    console.log('GETTING LIKED PROJECTS');
    try {
        console.log('QUERYING  PROJECTS');

        let q;

        if(lastPageDoc) {
            // Create a query to retrieve projects where the like_id_list contains the provided userId, starting at the last visible document and fetch the next 3 projects.
            q = query(projectsCollectionRef, where("like_id_list", "array-contains", userId), orderBy('lastModified', 'desc'), startAfter(lastPageDoc || 0), limit(10));
        } else {
            // Create a query to retrieve projects where the like_id_list contains the provided userId
            q = query(projectsCollectionRef, where("like_id_list", "array-contains", userId), orderBy('lastModified', 'desc'), limit(10));
        }

        // Execute the query and retrieve the query snapshot
        onSnapshot(q, async (snapshot) => {
            console.log('UPDATING LIKED PROJECTS...');
            const tempProjectList = snapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

            // Recursively fetch comments of comments
            const fetchCommentsOfComments = async (comments) => {
                for (let i = 0; i < comments.length; i++) {
                const comment = comments[i];
                
                // Create a query to retrieve comments where the post_commented_on field matches the comment ID, ordered by creation date in descending order
                const commentOfCommentQuery = query(projectsCollectionRef, where("post_commented_on", "==", comment._id), orderBy('createdAt', 'asc'));
                
                // Execute the query and retrieve the query snapshot
                const commentOfCommentSnapshot = await getDocs(commentOfCommentQuery);
                
                // Map through the document snapshots in the query snapshot and extract the data and ID for each comment of comment
                const commentsOfComments = commentOfCommentSnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));
                
                // Append the comments of comments to the current comment
                comment.commentsOfComments = commentsOfComments;
                
                // Recursively fetch comments of comments for the current comment
                await fetchCommentsOfComments(commentsOfComments);
                }
            };
            
            // Fetch comments of comments for the initial set of comments
            await fetchCommentsOfComments(tempProjectList);

            let lastDocument = tempProjectList[tempProjectList.length - 1];

            if(lastDocument) {

                // Create a reference to the specified post document in the "projects" collection 
                const docRef = doc(projectsCollectionRef, lastDocument._id);
    
                // Retrieve the document with the specified ID from the "projects" collection
                const projectDoc = await getDoc(docRef);
    
                // Set the last visible document for the next load
                dispatch({
                    type: SET_LAST_PAGE_DOC_PROJECT,
                    payload: projectDoc
                });
    
                dispatch(set_ShowBottomSpinner(false));
            }

            // Dispatch an action of type SET_PROJECTS or SET_LAST_PROJECT with the projects as the payload
            if(tempProjectList.length < 9 && lastPageDoc) {
                dispatch({
                    type: SET_LAST_PROJECTS,
                    payload: tempProjectList
                });
            } else {
                dispatch({
                    type: SET_PROJECTS,
                    payload: tempProjectList
                });
            }
            
        })

    } catch (err) {
        console.log('ERROR!!!')
        console.log(err)

        // Dispatch an action of type SET_PROJECTS with an empty array as the payload in case of an error
        dispatch({
            type: SET_PROJECTS,
            payload: []
        })
    }
};

// Fill the projects array with everything in the given array
export const setProjects = arrayOfProjects => dispatch => {

    dispatch({
        type: GET_PROJECTS,
        payload: arrayOfProjects
    });
};

// Add Post
export const addProject = ( 
    formData, 
    imgData, 
    imgDimensions, 
) => async dispatch => {
    
    try {

        if(imgData?.length > 0) {
            dispatch({
                type: PROJECT_UPLOADING,
                payload: true
            });
        }

        // Create Project

        // Extract the necessary data from the formData object
        const {
            title,
            text,
            username,
            user,
            project_link,
            icon_link
        } = formData;

        let category = null;
        let avatar = null;
        

        // Check if the category and avatar fields exist in the formData object
        // If they exist, assign their values to the respective variables
        if(formData.category) {
            category = formData.category;
        }
        if(formData.avatar) {
            avatar = formData.avatar;
        }

        // Create a new document in the "posts" collection with the post data
        const result = await addDoc(projectsCollectionRef, {
            title,
            project_link,
            icon_link,
            text,
            category,
            username,
            user,
            avatar,
            img_count: imgData.length,
            likes: [],
            comments: [],
            date: Date.now(),
            createdAt: serverTimestamp(),
            lastModified: serverTimestamp()
        });

        console.log('PROJECT CREATED')


        // --- Update the totalProjects # in the user document
            

        const userRef = doc(db, 'users', user._id)

        console.log('GOT USER REFERENCE');

        // Update the totalProjects field using the increment function
        await updateDoc(userRef, {
            totalProjects: increment(1)
        })
           
            
        // END update the totalProjects # in the user document

        
        // --- Add Images to project ---


                // Get the document reference for the post
                const docRef = doc(db, 'projects', result.id)

                // Get the collection reference for the image gallery subcollection
                const colRef = collection(docRef, "img_gallery")

                let imgUpload_counter = 0;
                
                // Iterate over the imgData array and upload each image to the storage
                imgData.map(async (img) => {
                    
                    // Create a storage reference for the image
                    const storageRef = ref(storage, `images/projects/${result.id}/${img.name}`);

                    // Upload the image file to the storage
                    const res = await uploadBytesResumable(storageRef, img);

                    console.log('Uploaded a blob or file!');

                    // Get the download URL of the uploaded image
                    const imgPath = await getDownloadURL(storageRef);

                    // Retrieve the existing image gallery data
                    const galleryData = await getDocs(colRef);

                    let orderNum = 1;
                    
                    // If existing gallery data has documents increment to orderNum to the sum + 1
                    if(galleryData.docs && galleryData.docs.length > 0) {
                        orderNum = galleryData.docs.length + 1
                    }

                    // Find the dimensions information of the current image
                    const imgInfo = imgDimensions.find(dimensionObj => dimensionObj.fileName === img.name);

                    console.log('IMG INFO - DIMENSIONS');
                    console.log(imgInfo);

                    let newImg = {}; 

                    if(imgInfo) {

                        // Create a new image object with dimensions information
                        newImg = {
                            img_path: imgPath,
                            img_name: img.name,
                            img_order: orderNum,
                            img_width: imgInfo.width,
                            img_height: imgInfo.height
                        };
                    } else {

                        // Create a new image object without dimensions information
                        newImg = {
                            img_path: imgPath,
                            img_name: img.name,
                            img_order: orderNum,
                            img_width: 0,
                            img_height: 0
                        };
                    }

                    // Add the new image to the "img_gallery" subcollection
                    const gal = await addDoc(colRef, newImg);

                    if(imgUpload_counter + 1 === imgData.length) {
                        dispatch({
                            type: PROJECT_UPLOADING,
                            payload: false
                        });

                        // Set a timeout to give enough time for the post to be added
                        setTimeout(() => {
            
                            // Display an alert message to notify the user that the post was sent
                            dispatch(setAlert('Your project was created.', 'okay'));
                        }, 1000 );

                    } else {
                        imgUpload_counter = imgUpload_counter + 1
                    }
                    
                    // await updateDoc(docRef, {
                    //     img_gallery: tempArray
                    // });
                });


            // await addImagesToPost(imgData);

        // --- END ADD IMGS ---



        // Get project with Images

        dispatch({
            type: UPDATE_LAST_PROJECT_ADDED,
            payload: result.id
        });

        if(!(imgData?.length > 0)) {
                
            // Display an alert message to notify the user that the project was created
            dispatch(setAlert('Your project was created.', 'okay'));
        }

    } catch (err) {
        console.log('ERROR!!!')
        console.log(err);
    //   dispatch({
    //     type: PROJECT_ERROR,
    //     payload: { msg: err.response.statusText, status: err.response.status }
    //   });
    }
};

// Delete Post
export const deleteProject = (projectId) => async dispatch => {
    console.log('DELETING PROJECT!!!!!')

    // TODO: Delete comment from project

    // Get reference to the project document
    const projectRef = doc(db, 'projects', projectId)

    // Get reference to the "img_gallery" subcollection of the project
    const colRef = collection(projectRef, "img_gallery")

    try {
        // --- Delete IMGS from project ---
            let galleryData;

            // Check if the "img_gallery" subcollection exists
            if(colRef) {
                // Fetch the documents in the "img_gallery" subcollection
                galleryData = await getDocs(colRef);
            }

            // If img_gallery HAS images
            if(galleryData?.docs && galleryData.docs.length > 0) {
                console.log('DELETING GALLERY')

                // Iterate over the galleryData and delete each image
                galleryData.docs.map(async (imgData) => {
                    try {
                        // Get reference to project img_gallery
                        const imgRef = doc(db, `projects/${projectId}/img_gallery`, imgData.id);
                    
                        console.log('DELETING IMGS PROCESS')
                        
                        // Get reference to the image file in the storage
                        const storageRef = ref(storage, `images/projects/${projectId}/${imgData.data().img_name}`);

                        // Delete the image file from the storage
                        await deleteObject(storageRef);

                        // Delete the image document from the "img_gallery" subcollection
                        await deleteDoc(imgRef);

                    } catch (err) {
                        console.log('ERROR');
                        console.log(err);

                        dispatch(setAlert('Couldn\'t delete project images', 'danger'));
                    }
                })
            }

        // --- END delete IMGS ---

        // --- UPDATE user total project # ---

            // Fetch the project document
            const projectDoc = await getDoc(projectRef);

            // Get reference to the user document of the project's owner
            const userRef = doc(db, 'users', projectDoc.data().user._id);

            console.log('GOT USER REFERENCE');
            
            // Decrement the totalProjects field of the user document by 1
            await updateDoc(userRef, {
                totalProjects: increment(-1)
            })
        
        // --- END update user total project # ---

        // --- DELETE project AND COMMENTS ---

        // Function to delete all of a project's comments
        const deleteProjectComments = async (projectData) => {
            try {
                // Create a query to retrieve comments where the project_commented_on field matches the provided post ID, ordered by creation date in descending order
                const commentQuery = query(postsCollectionRef, where("project_commented_on", "==", projectData.id));
            
                // Execute the query and retrieve the query snapshot
                const querySnapshot = await getDocs(commentQuery);
            
                // Map through the document snapshots in the query snapshot and extract the data and ID for each comment
                const projectComments = querySnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

                console.log('GOT project COMMENTS');
            
                // Recursively fetch comments of comments
                const fetchCommentsOfComments = async (comments) => {
                    console.log('FETCHING COMMENTS OF COMMENTS');
                    for (let i = 0; i < comments.length; i++) {

                        console.log('SINGLE COMMENT');

                        const comment = comments[i];
                        
                        // Create a query to retrieve comments where the post_commented_on field matches the comment ID, ordered by creation date in descending order
                        const commentOfCommentQuery = query(postsCollectionRef, where("post_commented_on", "==", comment._id), orderBy('createdAt', 'desc'));
                        
                        // Execute the query and retrieve the query snapshot
                        const commentOfCommentSnapshot = await getDocs(commentOfCommentQuery);
                        
                        // Map through the document snapshots in the query snapshot and extract the data and ID for each comment of comment
                        const commentsOfComments = commentOfCommentSnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));
                        
                        // Append the comments of comments to the current comment
                        comment.commentsOfComments = commentsOfComments;

                        console.log('END SINGLE COMMENT');
                        
                        // Recursively fetch comments of comments for the current comment
                        await fetchCommentsOfComments(commentsOfComments);
                    }

                    console.log('END FETCHING COMMENTS OF COMMENTS');
                };
            
                // Fetch comments of comments for the initial set of comments
                await fetchCommentsOfComments(projectComments);
            
                // Now projectComments array contains all comments and comments of comments
                console.log('--- > ALL COMMENTS');

                console.log('START DELETING COMMENTS')

                const deleteCommentsOfComments = async (comments) => {
                    console.log('DELETING COMMENTS OF COMMENTS');
                    for (let i = 0; i < comments.length; i++) {
              
                      console.log('SINGLE COMMENT');
              
                      const comment = comments[i];

                      // --- Remove the IMGS of the comment

                      const removeCommentImgs = async (commentData) => {
                        const postCommentRef = doc(db, 'posts', commentData._id)

                        // Get reference to the "img_gallery" subcollection of the post
                        const commentColRef = collection(postCommentRef, "img_gallery")

                        let commentGalleryData;

                        // Check if the "img_gallery" subcollection exists
                        if(commentColRef) {
                            // Fetch the documents in the "img_gallery" subcollection
                            commentGalleryData = await getDocs(commentColRef);
                        }

                        // If img_gallery HAS images
                        if(commentGalleryData?.docs && commentGalleryData.docs.length > 0) {
                            console.log('DELETING GALLERY')

                            // Iterate over the commentGalleryData and delete each image
                            commentGalleryData.docs.map(async (imgData) => {
                                try {
                                    // Get reference to post img_gallery
                                    const imgRef = doc(db, `posts/${commentData._id}/img_gallery`, imgData.id);
                                
                                    console.log('DELETING IMGS PROCESS')
                                    
                                    // Get reference to the image file in the storage
                                    const storageRef = ref(storage, `images/posts/${commentData._id}/${imgData.data().img_name}`);

                                    // Delete the image file from the storage
                                    await deleteObject(storageRef);

                                    // Delete the image document from the "img_gallery" subcollection
                                    await deleteDoc(imgRef);

                                    console.log('END deleting IMGS process')

                                } catch (err) {
                                    console.log('ERROR');
                                    console.log(err);

                                    dispatch(setAlert('Couldn\'t delete post images', 'danger'));
                                }
                            })
                        }
                      }

                      await removeCommentImgs(comment);

                      // --- END Remove the IMGS of the comment
              
                      // Delete the current comment
                      await deleteDoc(doc(db, 'posts', comment._id));

                        // Dispatch the REMOVE_PROJECT_COMMENT action with the commentId payload to remove the comment from the state
                        dispatch({
                            type: REMOVE_PROJECT_COMMENT,
                            payload: comment._id
                        });
              
                      // Delete comments of comments recursively
                      if (comment.commentsOfComments && comment.commentsOfComments.length > 0) {
                        await deleteCommentsOfComments(comment.commentsOfComments);
                      }
              
                      console.log('END SINGLE COMMENT');
                    }
              
                    console.log('END DELETING COMMENTS OF COMMENTS');
                };
              
                // Delete comments of comments for the initial set of comments
                await deleteCommentsOfComments(projectComments);

                console.log('END DELETING COMMENTS')
            } catch (error) {
                console.log('ERROR DELETING project COMMENTS')
                console.log(error);
            }

            
        };
    
        await deleteProjectComments(projectDoc);
        
        // Delete the project document
        await deleteDoc(projectRef);

        // --- END DELETE project AND COMMENTS ---
        

        // Dispatch the DELETE_PROJECT action with the projectId
        dispatch({
            type: DELETE_PROJECT,
            payload: projectId
        });

        // Clear the currently displayed project
        dispatch(clearProject());

        // Set an alert to inform the user that the project was deleted
        dispatch(setAlert('Your project was deleted', 'okay'));
        

    } catch (err) {
        console.log('ERROR');
        console.log(err);

        dispatch(setAlert('Something went wrong', 'danger'));
    //     dispatch({
    //         type: PROJECT_ERROR,
    //         payload: { msg: err.response.statusText, status: err.response.status }
    //     });
    }
}

export const setProjectUploadingImgs = (value) => dispatch => {
    dispatch({
        type: PROJECT_UPLOADING_IMGS,
        payload: value
    });
}

// Add like
export const addLike = (campus_name, projectId, fromUserData, projectData) => async dispatch => {
    
    // Get the current list of likes from the project data
    const likeList = projectData.likes;
    
    try {
        console.log('ADDING LIKE!!!!!')

        // Get Firebase project & Likes of project collection ref
        const projectRef = doc(db, 'projects', projectId)
        const colRef = collection(projectRef, "likes")
        
        // Create the new like object
        const newLike = {
            user: {
                _id: fromUserData._id,
                username: fromUserData.username,
                first_name: fromUserData.first_name,
                last_name: fromUserData.last_name,
                img: fromUserData.img
            }
        };

        // Check if project already liked by same user
        if(likeList.filter(like => like.user._id.toString() === fromUserData._id).length > 0) {
            // Get the index of the like to remove
            const removeIndex = likeList.map(like => like.user._id.toString()).indexOf(fromUserData._id);

            const likeID = likeList[removeIndex]._id;

            // Remove the like from the project
            likeList.splice(removeIndex, 1);

            // Create array for the list of like ID's
            const id_array = [];

            likeList.map((like) => {
                id_array.push(like.user._id);
            })

            // Update the project document with the updated likes and like_id_list
            await updateDoc(projectRef, {
                likes: likeList,
                like_id_list: id_array
            })
        } else {
            // const likeData = await addDoc(colRef, newLike);

            // Add the new like to the likeList
            likeList.push(newLike);

            // Create array for the list of like ID's
            const id_array = [];

            likeList.map((like) => {
                id_array.push(like.user._id);
            })

            // Update the project document with the updated likes and like_id_list
            await updateDoc(projectRef, {
                likes: likeList,
                like_id_list: id_array
            })

            console.log('SUCCESSFULLY LIKED');
            

            // Dispatch an alert to indicate that the project was saved
            dispatch(setAlert('project saved.', 'okay'));

        }

      
    } catch (err) {
      console.log(err)
    }
};

// Get project Comment
export const getComments = (projectId) => async dispatch => {
    dispatch(setCommentsLoading());
    console.log('GETTING COMMENTS')

    try {
        // Create a query to retrieve comments where the project_commented_on field matches the provided post ID, ordered by creation date in descending order
        const commentQuery = query(postsCollectionRef, where("project_commented_on", "==", projectId), orderBy('createdAt', 'asc'));
      
        // Execute the query and retrieve the query snapshot
        const querySnapshot = await getDocs(commentQuery);
      
        // Map through the document snapshots in the query snapshot and extract the data and ID for each comment
        const projectComments = querySnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));
      
        // Recursively fetch comments of comments
        const fetchCommentsOfComments = async (comments) => {
          for (let i = 0; i < comments.length; i++) {
            const comment = comments[i];
            
            // Create a query to retrieve comments where the post_commented_on field matches the comment ID, ordered by creation date in descending order
            const commentOfCommentQuery = query(postsCollectionRef, where("post_commented_on", "==", comment._id), orderBy('createdAt', 'desc'));
            
            // Execute the query and retrieve the query snapshot
            const commentOfCommentSnapshot = await getDocs(commentOfCommentQuery);
            
            // Map through the document snapshots in the query snapshot and extract the data and ID for each comment of comment
            const commentsOfComments = commentOfCommentSnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));
            
            // Append the comments of comments to the current comment
            comment.commentsOfComments = commentsOfComments;
            
            // Recursively fetch comments of comments for the current comment
            await fetchCommentsOfComments(commentsOfComments);
          }
        };
      
        // Fetch comments of comments for the initial set of comments
        await fetchCommentsOfComments(projectComments);
      
        // Now projectComments array contains all comments and comments of comments
        console.log('--- > ALL COMMENTS');

        dispatch({
            type: GET_PROJECT_COMMENTS,
            payload: projectComments
        });
      } catch (error) {
        console.log('ERROR');
        console.log(error);
      }

    // try {
    //     console.log('GETTING COMMENTS 2')

    //     // Get the reference to the post and comments collection
    //     const postRef = doc(db, 'posts', projectId);
    //     const commentsCollectionRef = collection(postRef, "comments")

    //     // Create a query to fetch the comments in descending order of createdAt
    //     const q = query(commentsCollectionRef, orderBy('createdAt', 'desc'));

    //     // Fetch the comment data
    //     const commentData = await getDocs(q);

    //     // Map the comment documents to an array of comment objects with the added _id property
    //     const commentList = commentData.docs.map((doc) => ({...doc.data(), _id: doc.id}));

    //     console.log('SHOW POST COMMENTS ListN ACTIONS');
    //     console.log(commentList)
    
    //     // Dispatch the comment list to the store
    //     dispatch({
    //         type: GET_PROJECT_COMMENTS,
    //         payload: commentList
    //     });

    // } catch (err) {
    //     console.log(err)

    //     // If an error occurs, dispatch an empty comment list to the store
    //     dispatch({
    //         type: SET_PROJECT_COMMENTS,
    //         payload: []
    //     })
    // }
}

// Add Comment
export const addComment = (projectId, formData, projectData, history) => async dispatch => {

    if(!formData.avatar) formData.avatar = "";
    
    try {
        
        console.log('IN COMMENT!!!!!')

        // Add comment to project

        // Get the reference to the project and comments collection
        const docRef = doc(db, 'projects', projectId)
        const colRef = collection(docRef, "comments")
        
        // Object that represents the comment to be added to the project
        const newComment = {
            text: formData.text,
            username: formData.username,
            avatar: formData.avatar,
            user: formData.user,
            date: Date.now(),
            createdAt: serverTimestamp()
        };

        // Add the new comment to the "comments" subcollection of the project
        const commentRef = await addDoc(colRef, newComment);

        // Fetch the added comment document
        const commentDoc = await getDoc(commentRef);

        // Create a full comment object by merging the comment data with the added _id property
        const fullComment = {
            ...commentDoc.data(),
            _id: commentDoc.id
        }

        console.log('---- Comment Data:');
        
        // Dispatch the full comment to the store using the SET_PROJECT_COMMENTS action type
        dispatch({
            type: SET_PROJECT_COMMENTS,
            payload: [fullComment]
        });

        // Display a success message to the user
        dispatch(setAlert('Your reply was sent', 'okay'));


        // Redirect the user to the project page
        if(history) {
            window.location.href = `/project/${projectId}`;
        }

    } catch (err) {
        console.log('ERRORS')
        console.log(err);
        
        // Check if the error has a response property
        if(err.response) {
            const errors = err.response.data.errors;

            // Check if there are errors in the response data
            if(errors) {

                // Loop through the errors and dispatch the setAlert action for each error
                errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
            }
        } else {

            // Dispatch the setAlert action with a generic error message if the error doesn't have a response property
            dispatch(setAlert('Something went wrong', 'danger'));
        }
        // dispatch({
        //     type: PROJECT_ERROR,
        //     payload: { msg: err.response.statusText, status: err.response.status }
        // });
        // console.log({msg: err.response.statusText, status: err.response.status})
    }
}

// Delete Comment
export const deleteComment = (projectId, commentId) => async dispatch => {
    console.log('DELETING COMMENT!!!!!')

    // Delete comment from project
    const docRef = doc(db, `projects/${projectId}/comments`, commentId)

    try {
        console.log('IN DELETE COMMENT');

        // Delete the comment document
        await deleteDoc(docRef);

        // Dispatch the REMOVE_PROJECT_COMMENT action with the commentId payload to remove the comment from the state
        dispatch({
            type: REMOVE_PROJECT_COMMENT,
            payload: commentId
        });

        // Display a success alert
        dispatch(setAlert('Comment Removed', 'success'));

    } catch (err) {

        console.log('ERROR');
        console.log(err);

        // Display an error alert if something goes wrong during the deletion
        dispatch(setAlert('Something went wrong', 'danger'));

        // Uncomment the following code if you have specific error handling logic or dispatches for the PROJECT_ERROR action
        // dispatch({
        //     type: PROJECT_ERROR,
        //     payload: { msg: err.response.statusText, status: err.response.status }
        // });
    }
}   

// Posts loading
export const setProjectsLoading = () => {
    return {
        type: PROJECTS_LOADING
    }
}

// Comments loading
export const setCommentsLoading = () => {
    return {
        type: PROJECT_COMMENTS_LOADING
    }
}

// Remove all projects
export const clearProjects = () => dispatch => {

    // Dispatch an action to clear all projects
    dispatch(setProjectsLoading());

    dispatch({
        type: CLEAR_PROJECTS
    });

}

// Remove detail project
export const clearProject = () => dispatch => {
    dispatch({
        type: CLEAR_PROJECT
    });

}

// --- Infinite Scroll Functions ---

export const set_ShowBottomSpinner = (value) => dispatch => {

    dispatch({
        type: SET_SHOW_BOTTOM_SPINNER_PROJECT,
        payload: value
    });

}

export const set_LastPageDoc = (lastProjectDoc) => dispatch => {

    dispatch({
        type: SET_LAST_PAGE_DOC_PROJECT,
        payload: lastProjectDoc
    });

}

export const set_NoMoreProjects = (value) => dispatch => {

    dispatch({
        type: SET_NO_MORE_PROJECTS,
        payload: value
    });

}

// --- END: Infinite Scroll Functions ---
import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, Link } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import ReactGA from 'react-ga4';

// ALGOLIA SEARCH
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits } from 'react-instantsearch-dom';

// Routing
import Routes from './components/routes/Routes';
import PrivateRoute from './components/routes/PrivateRoute';
import Body from './Body';

// Stripe API
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// CSS - controlled by /scss
import './css/main.css';

// Redux
import { Provider } from 'react-redux';
import store from './store';

// Actions
import { loadUser } from './actions/authActions';
import { set_campus_id, set_country_code, set_isVPN } from './actions/navActions';

// Components - imported
import Sidebar from './components/sidebar/Sidebar';
import AlertContainer from './components/common/Alerts/AlertContainer';


// Pages - imported
import SetUpProfile from './components/Profile/SetUpProfile/SetUpProfile';

// Auth Pages
import AuthPage from './pages/auth/AuthPage';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import ForgotPassword from './pages/auth/ForgotPassword';
import Logout from './pages/auth/Logout';
import ClaimAccount from './pages/auth/ClaimAccount';
import CreatePost from './pages/create/CreatePost';
import CreateComment from './pages/create/CreateComment';

// Other Pages
import Page_For_Scammers from './pages/Page_For_Scammers';

const App = () => {
  // Initializing analytics
  const initializeAnalytics = () => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    mixpanel.init(process.env.REACT_APP_MIXPANEL_ID, {
      debug: true
    });
  }

  // Window Width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // URL and filter
  const url_filter = (window.location.href);
  const url = new URL(url_filter);

  const isInitialLoad = useRef(true); // Tracks the initial load

  // Handling window resize
  useEffect(() => {
    // Handling window resize event
    window.addEventListener('resize', handleWindowSizeChange);

    // Cleaning up the event listener on component unmount
    return () => window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  // User Logic (load the user and initialize mixpanel tracking)
  useEffect(() => {
    // Dispatching the action to load the user
    store.dispatch(loadUser());

    if (process.env.NODE_ENV === 'production') {
      // Initializing analytics in production environment
      initializeAnalytics();
    }

  }, []);

  // Campus Logic (set campus ID)
  useEffect(() => {
    const hostname = window.location.hostname;

    if(hostname.includes('campusbuysell')) { // Campus Buy Sell
      store.dispatch(set_campus_id('sWlhtpjZ3UFPMwFF4vJX'));
    } 
    else if(hostname.includes('oubuysell') || hostname.includes('localhost')) { // University of Oklahoma
      store.dispatch(set_campus_id('b7nqQGbbbzw08uh0MoPA'));
    } 
    else if(hostname.includes('utbuysell')) { // Univeristy of Texas
      store.dispatch(set_campus_id('j4R70faL7jaXTR5Nzeex'));
    } 
    else if(hostname.includes('smubuysell')) { // Southern Methodist University (SMU)
      store.dispatch(set_campus_id('fNie9ly0jB0cs3tZHQaQ'));
    } 
    else if(hostname.includes('ufbuysell')) { // Univeristy of Florida (UF)
      store.dispatch(set_campus_id('g4O5N3JOeQPiMxoSbCex'));
    } 
    else { // University of Oklahoma (Default)
      store.dispatch(set_campus_id('b7nqQGbbbzw08uh0MoPA'));
    }

  }, []);

  const handleWindowSizeChange = () => {
    // Updating the window width state on window resize
    setWindowWidth(window.innerWidth);
  };

  // Determine window size breakpoints
  const isMobile = windowWidth <= 769;
  const isTablet = windowWidth <= 1000;

  return (
      <Provider store={store}>
        <Router>
          <Switch>

            <Route exact path="/404" component={Page_For_Scammers} />
            <Route exact path="/user_registration" component={AuthPage} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/forgotpassword" component={ForgotPassword} />
            <PrivateRoute exact path="/logout" component={Logout} />
            <Route exact path="/login/sent" component={ClaimAccount} />
            <PrivateRoute exact path="/profile/set-up" component={SetUpProfile} />
            <PrivateRoute exact path="/create/post" component={CreatePost} />
            
            <PrivateRoute exact path="/create/comment/:id" component={CreateComment} />

            <Body />
          </Switch>

          <AlertContainer />
        </Router>
      </Provider>
  );
}

export default App;

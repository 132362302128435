import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Actions
import { changeProfileImg } from '../../actions/authActions';
import { setAlert } from '../../actions/alertActions';

// Components - material UI
import { Avatar, Button } from '@material-ui/core';

// Modal
import Modal from '../modal/Modal';
import ModalContainer from '../modal/ModalContainer';

// Icons - material UI
import CloseIcon from '@material-ui/icons/Close';


const ProfileEdit = ({ 
    changeProfileImg, 
    toggleEdit, 
    toggleNameModal, 
    toggleBioModal, 
    auth: { 
        user 
    },
    setAlert
}) => {

    const [fileUrl, setFileUrl] = useState('');

    // Function to trigger the file upload input
    const fileUploadButton = () => {
        document.getElementById('fileButton').click();
    }

    // Event handler for file selection
    const fileChanged = e => {

        // Array to store the selected files
        let fileList = [];

        // Iterate through the selected files
        for (var i = 0; i < e.target.files.length; i++) {

            if(!e.target.files[i]) return;

            const fileName = e.target.files[i].name;

            // --- File extension

            const fileExt = fileName.substring(fileName.lastIndexOf('.')+1, fileName.length)
            console.log('File ext: ', fileExt);

            if(!(fileExt.includes('jpeg') || fileExt.toLowerCase().includes('jpg') || fileExt.includes('png') || fileExt.includes('gif'))) {
                setAlert('Only photos are allowed!', 'danger');
                return;
            }

            // --- END File extension

            // Add the file to the fileList array
            fileList.push(e.target.files[i])

            let reader = new FileReader()

            // Read the file as data URL
            reader.readAsDataURL(e.target.files[i])
            reader.onload = () => {
                console.log('FILE PATH')

                // Display the file path (data URL)
                console.log(reader.result);

                // Update the file URL state with the new data URL
                setFileUrl(fileUrl => [...fileUrl, reader.result])
            };

            // Display if there is an error reading the file
            reader.onerror = function (error) {
                console.log('Error: ', error);
            }
        }

        // Call the changeProfileImg function with the fileList and userId parameters
        changeProfileImg(fileList, user._id);

        console.log('USER IMG ID: ');

        // Display the user image ID
        console.log(user._id);


        // --- Additional code may exist here to handle further actions after file selection

        // window.location.reload();

        // setFiles(fileList);
        // setFormData({ ...formData, [e.target.name]: e.target.files[0] });
    }

    // --- Set the initial value for the 'bio' variable based on user data

    let bio;

    if(user.bio && user.bio !== '') {
        bio = user.bio;
    } else {
        bio = 'Bio'
    }

    return (
        <Fragment>
            <div className="profile__header">
                {/* <div className="closeBtn__container">
                    <button onClick={toggleEdit} className="secondaryBtn">
                        <CloseIcon /> 
                        <span>Close</span>
                    </button>
                </div> */}

                {/* Profile image section */}
                <div className="profile__image">
                    <Avatar src={fileUrl !== '' ? fileUrl : user.img} />
                </div>

                {/* Change photo button */}
                <Button 
                    onClick={fileUploadButton}
                    variant="outlined" 
                    className="sidebar__tweet ghost noMargin"
                >
                    Change photo
                </Button>

                {/* Hidden file input for photo selection */}
                <input
                    onChange={fileChanged} 
                    id="fileButton" 
                    type="file" 
                    accept="image/png, image/gif, image/jpeg, image/jpg"
                    hidden
                />

                {/* Profile Info section */}
                <div className="edit__body">
                    
                    {/* Name section */}
                    <div onClick={toggleNameModal} style={{cursor:'pointer'}} className="settings__section">
                        <div>
                            <p>
                                {user.first_name}{' '}{user.last_name}
                            </p>
                        </div>

                        {/* Edit btn */}
                        <div style={{cursor:'pointer'}}>
                            <p>Edit</p>
                        </div>
                    </div>

                    {/* Bio section */}
                    <div onClick={toggleBioModal} style={{cursor:'pointer'}} className="settings__section">
                        <div>
                            <p>
                                {bio && bio !== '' ? bio.slice(0, 160) : 'Bio'}
                            </p>
                        </div>

                        {/* Edit btn */}
                        <div style={{cursor:'pointer'}}>
                            <p>Edit</p>
                        </div>
                    </div>

                    {/* Additional sections */}

                    {/* <div className="settings__section">
                        <div>
                            <p>
                                Location
                            </p>
                        </div>
                        <div>
                            <p>Edit</p>
                        </div>
                    </div> */}
                </div>
            </div>
        </Fragment>
    )
}

ProfileEdit.propTypes = {
    // Prop type validation for authentication
    auth: PropTypes.object.isRequired,
    // Prop Type validation for function to change profile img
    changeProfileImg: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired
}

const mapStateToProps = state => ({

    // Mapping the authentication state
    auth: state.auth
});

export default connect(mapStateToProps, { 

    // Connecting actions to the component
    changeProfileImg,
    setAlert
})(ProfileEdit);

import React, { Fragment, useEffect, useState } from 'react'

import mixpanel from 'mixpanel-browser';

// Layout Wrapper
import ModalPage from '../components/layout/ModalPage';

const Page_For_Scammers = () => {

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Function to handle Mixpanel analytics
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Access Blocked Page View");
    }

    // Trigger Mixpanel analytics on initial render in production mode
    if(process.env.NODE_ENV === 'production' && !sentMixpanel) {
        handleMixpanel();
        setSentMixpanel(true);
    }

    return (
        <ModalPage>
            <div className="form-transition-element">

                {/* Header Description */}
                <div>
                    <h2>Error 404</h2>
                </div>

                {/* Form footer */}
                <div className="auth__footer">

                </div>
            </div>
        </ModalPage>
    )
}

export default Page_For_Scammers;

export const bannedEmails = [
    'melodysmith820@gmail.com',
    'clipinitial492@gmail.com',
    'lindamorrison062@gmail.com',
    'mistressannabel172@gmail.com',
    'mistressannabel080@gmail.com',
    'marvelranky0806@gmail.com',
    'trukillustration@gmail.com',
    'deborahlove953@gmail.com',
    'oduruparadise1@gmail.com',
    'oduruanointed295@gmail.com',
    'odurutestimony@gmail.com',
    'njackie388@gmail.com',
    'ripleyr27@gmail.com',
    'chajonviejob@hotmail.com',
    'lucas.j.andrew2233@gmail.com',
    'asuquodave207@gmail.com',
    'carranzafrancisco099@gmail.com',
    'abosededamilola416@gmail.com',
    'evansasuquo9@gmail.com'
    // Add more banned emails here
];
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
 
// Define the initial state of the Redux store
const initialState = {}
 
// Create an array to hold the middleware, in this case, only Redux Thunk
const middleware = [thunk]
 
// Enable the Redux DevTools extension in the browser or fallback to the compose function
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
 
// Create the Redux store
const store = createStore(
  rootReducer, // The combined reducer that represents the state tree of application
  initialState, // The initial state of the store
  composeEnhancers( // Apply the middleware and enable the Redux DevTools extension
    applyMiddleware(...middleware))
);
 
export default store;
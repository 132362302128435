import React from 'react'
import PropTypes from 'prop-types'

const Readme = props => {
    return (
        <div className='readme_body'>
            <main>
        <h1 id="tic-tac-toe-game">Tic Tac Toe Game</h1>
<p>A simple Tic Tac Toe game built with <strong>Python</strong> and <strong>Pygame</strong>. This project serves as a fun introduction to game development using Python and can optionally be hosted on the web using <strong>asyncio</strong> with the Pygbag library.</p>
<p><img src="https://github.com/tunde262/tic_tac_toe_game_python/blob/main/assets/thumbnail.png?raw=true" alt="thumbnail" /></p>
<h2 id="features">Features</h2>
<ul>
<li><strong>Interactive Gameplay</strong>: Play Tic Tac Toe with two players taking turns.</li>
<li><strong>Win Detection</strong>: Automatically detects wins (horizontal, vertical, or diagonal) and highlights the winning line.</li>
<li><strong>Restart Functionality</strong>: Reset the game at any time by pressing the <code>R</code> key.</li>
<li><strong>Simple Design</strong>: Clean interface with responsive grid and smooth drawing animations.</li>
<li><strong>Optional Web Hosting</strong>: The game can be hosted on the web using asyncio compatibility with the Pygbag library.</li>
</ul>
<hr />
<h2 id="requirements">Requirements</h2>
<ul>
<li>Python 3.8+, recommended for the following reasons:<ol>
<li><strong>Enhanced <code>asyncio</code> Support</strong>: The project uses <code>asyncio.run()</code> for the optional web-hosting feature, which simplifies asynchronous execution.</li>
<li><strong>Compatibility with Pygbag</strong>: Pygbag, used for web hosting, requires Python 3.8+.</li></ol></li>
<li>Pygame</li>
<li>NumPy</li>
<li>(Optional) Pygbag for web hosting</li>
</ul>
<h2 id="installation">Installation</h2>
<ol>
<li><strong>Clone the Repository</strong>:</li>
</ol>
<pre><code class="hljs bash language-bash">   git <span class="hljs-built_in">clone</span> https://github.com/tunde262/tic_tac_toe_game_python.git
   <span class="hljs-built_in">cd</span> tic_tac_toe_game_python</code></pre>
<ol start="3">
<li><strong>Install Dependencies</strong>: Ensure you have Python 3.8+ installed. Then, install the required libraries:</li>
</ol>
<pre><code class="hljs bash language-bash">   pip install pygame numpy</code></pre>
<ol start="4">
<li><strong>Run the Game</strong>:</li>
</ol>
<pre><code class="hljs bash language-bash">   python main.py</code></pre>
<hr />
<h2 id="web-hosting-optional">Web Hosting (Optional)</h2>
<p>This project supports optional web hosting using <code>asyncio</code> and the <strong>Pygbag</strong> library.</p>
<ol>
<li><strong>Install the Pygbag library</strong>:</li>
</ol>
<pre><code class="hljs bash language-bash">   pip install pygbag</code></pre>
<ol start="2">
<li><strong>Compile and host the game</strong>:</li>
</ol>
<pre><code class="hljs bash language-bash">   pygbag main.py</code></pre>
<ol start="3">
<li>Follow the output instructions to serve the game in a browser.</li>
</ol>
<h2 id="how-to-play">How to Play</h2>
<ol>
<li>Start the Game: Run the script to open the game window.</li>
<li>Take Turns:<ul>
<li>Player 1: Draws circles.</li>
<li>Player 2: Draws crosses.</li></ul></li>
<li>Win the Game:<ul>
<li>Get three symbols in a row, column, or diagonal to win.</li></ul></li>
<li>Restart: Press the <code>R</code> key to reset the game board.</li>
</ol>
<h2 id="game-controls">Game Controls</h2>
<ul>
<li><code>Mouse</code>: Place a symbol</li>
<li><code>R</code>:     Restart the game</li>
</ul>
<hr />
<h2 id="future-enhancements">Future Enhancements</h2>
<ul>
<li>Add an AI competitor.</li>
<li>Mobile-friendly version with touch controls.</li>
<li>Add on-screen instructions.</li>
</ul>
<hr />
<h3 id="author">Author</h3>
<p><em>Tunde Adepitan</em>  <br />
GitHub: <a href="https://github.com/tunde262">tunde262</a>  <br />
Feel free to connect and give me feedback!</p>
            </main>
        </div>
  )
}

Readme.propTypes = {}

export default Readme;
import React, { Fragment } from 'react'
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Actions
import { toggleProfileModal } from '../../../actions/navActions';

// Components - imported
import { Button } from '@material-ui/core';
import Logo from '../../common/Logo';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const Confirm = ({
    slideform2, 
    setSlideForm2,
    toggleProfileModal,
    user
}) => {

    // Go back to the previous form step
    const goBack = (e) => {
        setSlideForm2(!slideform2);
    }

    // Handle the action when confirming the profile update
    // (Close the edit profile modal && redirect to profile page)
    const todo = (e) => {
        // Close edit profile modal
        toggleProfileModal();

        // Redirect to the user's profile page
        window.location.href = `/${user.username}`;
    }

    return (
        <div className="form-transition-element">

            {/* Back Button */}
            <div onClick={goBack} className="form__backBtn">
                <ArrowBackIcon />
            </div>

            {/* Form Body */}
            <div style={{height: '75%', width:'fit-content', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>

                {/* Logo */}
                <Logo />

                {/* Form Title */}
                <div style={{marginTop:'20px'}}>
                    <h2>Your profile is updated</h2>
                </div>

                {/* Form Actions */}
                <div style={{width:'100%', marginTop:'10px'}}>

                    {/* Submit Button */}
                    <Button 
                        variant="outlined" 
                        className="sidebar__tweet black" 
                        fullWidth
                        onClick={todo}
                    >
                        See profile
                    </Button>
                </div>
            </div>

        </div>
    )
}

Confirm.propTypes = {
    // Function to toggle the profile modal
    toggleProfileModal: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { 

    // Connecting actions to the component
    toggleProfileModal 
})(Confirm);
